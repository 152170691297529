import React, { useEffect, useState } from "react";
import { Card, CardContent, Box, Typography, Button, IconButton, Avatar, Grid, Menu, Skeleton, } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FireFilled from '../../../images/Plansom/FireFilled.png'
import picture from '../../../images/Plansom/picture.png'
import teacher from '../../../images/Plansom/teacher.png'
import button from '../../../images/Plansom/button.png'
import { getQuickWinsData } from "../../../services/OrganizationService";
import { CustomScrollbars } from "../Component/customScrollbarComponent";
import CloseIcon from "@mui/icons-material/Close";
import { getTaskDetailByTaskId, taskFeedback } from "../../../services/TaskService";
import { toast } from "react-toastify";
import { updatedGoalsWins, updatedProgressBarWins, updatedStreakWins, updateStreak } from "../../../store/actions/TaskActions";
import { useSelector, useDispatch } from 'react-redux';
import { updatedStreakEdit } from "../../../store/actions/TaskActions";
import { fetchAllMyDayData } from "../../../store/actions/NotificationAction";
import TaskEdit from "./TaskEdit";

const TaskCard = () => {
  const [quikWinsData, setQuickWinsData] = useState([]);
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);
  const [showingTask, setShowingTask] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [streakCount, setStreakCount] = useState(0);
  const [taskDetails, setTaskDetails] = useState();
  const [UpdateTaskRefresh, setUpdateTaskRefresh] = useState(false);

  const stateData = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % quikWinsData.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + quikWinsData.length) % quikWinsData.length
    );
  };

  const currentTask = quikWinsData[currentIndex];

  const getQuickWinsDatas = () => {
    setLoadingData(true);
    const data = getQuickWinsData(userInfoDetails?.data?.accessToken);
    data.then(async (res) => {
      dispatch(fetchAllMyDayData(false));
      setQuickWinsData(res?.data?.data?.quick_wins_data);
      setStreakCount(res?.data?.data?.streak_count);
      if (localStorage.getItem("OnboardingSkeleton") === "true") {
        setLoadingData(true);
      }
      else {
        setLoadingData(false);
      }
      dispatch(updateStreak(false))
      dispatch(updatedStreakEdit(false))
    });
    data.catch(async (err) => {
      // setSubShowTableSpinner(false);
    });
  };

  useEffect(() => {
    if (stateData.messages.fetchAllMyDayData) {
      getQuickWinsDatas();
    }
  }, [stateData]);


  useEffect(() => {
    getQuickWinsDatas()
  }, [])

  useEffect(() => {
    if (stateData.task.updateStreak) {
      getQuickWinsDatas()
    }
  }, [stateData.task.updateStreak])

  useEffect(() => {
    if (stateData.task.updatedStreakEdit) {
      getQuickWinsDatas()
    }
  }, [stateData.task.updatedStreakEdit])


  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget); // Set the clicked element as the anchor
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  function formatResponse(response) {
    // Replace headers
    response = response?.replace(/### (.*$)/gim, "<h3>$1</h3>");

    // Replace bold text
    response = response?.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");

    // Replace inline code
    response = response?.replace(/`([^`]+)`/g, "<code>$1</code>");

    // Replace ordered lists
    response = response?.replace(/(?:\d+\..*\n?)+/g, function (match) {
      let items = match?.trim().split(/\n(?=\d+\.\s)/); // Split based on numbers
      let listItems = items
        ?.map((item) => {
          let number = item?.match(/^\d+/)[0]; // Extract number
          let content = item?.replace(/^\d+\.\s*/, ""); // Remove number
          return `<li><b>${number}</b>. ${content}</li>`;
        })
        .join("");
      return `<ol>${listItems}</ol>`;
    });

    // Replace line breaks with <br>
    response = response?.replace(/\n/g, "<br>");

    return response;
  }

  function getfeedbackTask(task, selectedTaskStatus) {
    const data = taskFeedback(
      task?.id,
      userInfoDetails?.data?.accessToken,
      true,
      selectedTaskStatus,
      task?.task_effort
    );
    data?.then(async (res) => {
      setAnchorEl(null);
      getQuickWinsDatas();
      setShowingTask(true);
      dispatch(updatedStreakWins(true))
      dispatch(updatedGoalsWins(true))
      dispatch(updatedProgressBarWins(true))
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  // color: "#131F4C",
                  fontSize: "22px",
                }}
              >
                Completed
              </h2>
              <h3
                style={{
                  // color: "#131F4C",
                  fontSize: "18px",
                }}
              >
                Your task has been mark as complete successfully!
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-success-container toast-success-container-after ",
        }
      );
    });
    data?.catch(async (err) => {
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              className="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2 style={{ fontSize: "22px" }}>Failed</h2>
              <h3 style={{ fontSize: "18px" }}>
                {err?.response?.data?.message}
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-error-container toast-error-container-after",
        }
      );
    });
  }

  const handleDoneButton = () => {
    getQuickWinsData();
    setShowingTask(false);
  };

  async function getTasksAllDetails(task) {
    try {
      const res = await getTaskDetailByTaskId(task?.id, userInfoDetails?.data?.accessToken);
      setTaskDetails(res?.data?.data);
    } catch (err) {
      console.error("Error fetching task details:", err);
    }
  }

  return (<>
    {quikWinsData?.length == 0 ? <Card
      sx={{
        borderRadius: 4,
        padding: 2,
        boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
        position: "relative",
        overflow: "visible",
        minHeight: "46vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CardContent >
        {loadingData ?
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%", // Make sure this matches the card height
                width: "100%", // Make sure this matches the card width
                marginTop: "20%"
              }}
            >
              <Skeleton variant="circular" width={150} height={150} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "3%"
              }}
            >
              <Skeleton style={{ marginBottom: "2%", width: "70%" }} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1%"
              }}
            >
              <Skeleton style={{ width: "30%" }} />
            </div>
          </> :
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              src={FireFilled}
              sx={{ width: 80, height: 80 }}
            />
            <Typography variant="h6" sx={{ marginTop: 1 }}>
              Great work!
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", marginTop: 1, marginBottom: 1 }}
            >
              Your current Quick Win
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", marginTop: 1, marginBottom: 1 }}
            >
              streak is {streakCount} days!
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Your next set of quick wins will appear tomorrow
            </Typography>
          </Box>}
      </CardContent>
    </Card> :
      <Card
        sx={{
          borderRadius: 4,
          padding: 2,
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
          position: "relative",
          overflow: "visible",
          minHeight: "46vh",
          cursor: "pointer"
        }}
        onClick={() => {
          if (currentTask) {
            getTasksAllDetails(currentTask?.task);
          }
        }}
      >
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Quick Wins
            </Typography>
            {!loadingData &&
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  src={FireFilled}
                  sx={{ width: 25, height: 25, mr: 1 }}
                />
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {streakCount}d Streak
                </Typography>
              </Box>
            }
          </Box>
          {loadingData ?
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%", // Make sure this matches the card height
                  width: "100%", // Make sure this matches the card width
                  marginTop: "20%"
                }}
              >
                <Skeleton variant="circular" width={150} height={150} />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "3%"
                }}
              >
                <Skeleton style={{ marginBottom: "2%", width: "70%" }} />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "1%"
                }}
              >
                <Skeleton style={{ width: "30%" }} />
              </div>
            </>
            :
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 4,
                  mt: 4
                }}
              >
                <img
                  src={picture}
                  alt="Task Icon"
                  style={{ width: "100px", borderRadius: "50%" }}
                />
              </Box>

              <Typography variant="body2" color="text.secondary" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Avatar
                  src={teacher}
                  sx={{ width: 15, height: 15, mr: 1 }}
                />
                <Box >Learning</Box>
              </Typography>

              <Typography
                variant="body1"
                sx={{ fontSize: "1rem", mb: 2, color: "#333", mt: 2, display: "flex", justifyContent: 'center' }}
              >
                {currentTask?.task?.name}
              </Typography>
              {/* Complete Task Button */}
              {showingTask ? <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    marginRight: "3px",
                    backgroundColor: "#1D3BAF",
                    color: "#fff",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    textTransform: "none",
                    fontWeight: "bold",
                    "&:hover": { backgroundColor: "#1D3BAF" },
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleDoneButton();
                  }}
                >
                  Complete Task
                </Button>

                <Box display="flex"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Grid
                    display="flex"
                    alignItems="center"
                    marginRight="4px"
                  >
                    <Avatar
                      src={button}
                      sx={{ width: 48, height: 48, mr: 1 }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleClick1(e);
                      }}
                    />

                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose1}
                      sx={{
                        "& .MuiPaper-root": {
                          // minHeight: '200px',
                          height: "350px",
                          minWidth: "200px",
                          maxWidth: "320px",
                          padding: "10px",
                          position: "relative",
                        },
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          position: "absolute",
                          top: 0,
                          right: 0,
                        }}
                      >
                        <IconButton onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleClose1();
                        }}
                        >
                          <CloseIcon />
                        </IconButton>
                        <CustomScrollbars height={"30vh"}>
                          <Typography
                            variant="p"
                            sx={{ marginTop: "2px", padding: "5px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: formatResponse(currentTask?.tips),
                              }}
                            />
                          </Typography>
                        </CustomScrollbars>
                      </Box>
                      <Typography
                        variant="h5"
                        sx={{ paddingLeft: "4px" }}
                      >
                        Tips
                      </Typography>
                    </Menu>
                  </Grid>

                </Box>
              </Box> : <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    maxWidth: "300px",
                    margin: "auto",
                    padding: "10px",

                    borderRadius: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      marginRight: "10px",
                      backgroundColor: "#1D3BAF",
                      color: "#fff",
                      borderRadius: "20px",
                      padding: "10px 20px",
                      textTransform: "none",
                      fontWeight: "bold",
                      "&:hover": { backgroundColor: "#1D3BAF" },
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      getfeedbackTask(currentTask?.task, "beat")
                    }}
                  >
                    Epic
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      marginRight: "10px",
                      backgroundColor: "#1D3BAF",
                      color: "#fff",
                      borderRadius: "20px",
                      padding: "10px 20px",
                      textTransform: "none",
                      fontWeight: "bold",
                      "&:hover": { backgroundColor: "#1D3BAF" },
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      getfeedbackTask(currentTask?.task, "hit")
                    }}
                  >
                    Ok
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      marginRight: "10px",
                      backgroundColor: "#1D3BAF",
                      color: "#fff",
                      borderRadius: "20px",
                      padding: "10px 20px",
                      textTransform: "none",
                      fontWeight: "bold",
                      "&:hover": { backgroundColor: "#1D3BAF" },
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      getfeedbackTask(currentTask?.task, "miss")
                    }}
                  >
                    Miss
                  </Button>

                </Box>
              </>}

              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handlePrev();
                }}
                sx={{
                  position: "absolute",
                  top: "40%",
                  left: "30px",
                  transform: "translateY(-50%)",
                  "&:hover": { backgroundColor: "#f1f1f1" },
                }}
                disabled={currentIndex === 0}
              >
                <ArrowBackIosIcon fontSize="small" color="#141B34" />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleNext();
                }}
                sx={{
                  position: "absolute",
                  top: "40%",
                  right: "30px",
                  transform: "translateY(-50%)",
                  "&:hover": { backgroundColor: "#f1f1f1" },
                }}
                disabled={currentIndex === quikWinsData.length - 1}
              >
                <ArrowForwardIosIcon fontSize="small" color="#141B34" />
              </IconButton>
            </>
          }
        </CardContent>
      </Card>}
    {taskDetails && (
      <TaskEdit
        task={taskDetails}
        setIsTaskUpdated={() => setTaskDetails()}
        setTask={() => setTaskDetails()}
        setUpdateTaskRefresh={() => setUpdateTaskRefresh()}
      />
    )}
  </>
  );
};

export default TaskCard;



