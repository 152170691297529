import { lazy, Suspense, useEffect } from "react";
/// Components
import Index from "./jsx/index";
import { connect, useDispatch } from "react-redux";
import { useNavigate, Routes } from "react-router-dom";
import { Route } from "react-router";
// action
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import { useLocation } from "react-router-dom";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./App.css";
import "./css/app.css";
import swal from "sweetalert";
import Solutions from "./jsx/layouts/StoreFront/Solutions";
import PartnerRegistration from "./jsx/pages/PartnerApply";
import PartnerSignUp from "./jsx/pages/PartnerSignUp";
import WebSocketInstance from "./jsx/services/WebSocket";
import TermsAndCondition from "./jsx/layouts/landingPage/TermsAndConditions";
import PrivacyPolicy from "./jsx/layouts/landingPage/PrivacyPolicy";

const Pricing = lazy(() => import("./jsx/layouts/StoreFront/Pricing"));
const TermsAndConditions = lazy(() =>
  import("./jsx/layouts/StoreFront/TermsAndConditions")
);
const Privacy = lazy(() => import("./jsx/layouts/StoreFront/Privacy"));
const CookiePolicy = lazy(() =>
  import("./jsx/layouts/StoreFront/CookiePolicy")
);
const ContacUsAndSupport = lazy(() =>
  import("./jsx/layouts/StoreFront/ContactUsAndSupport")
);
const BecomingPlansomPartner = lazy(() =>
  import("./jsx/layouts/StoreFront/BecomigPlansomPartner")
);
const UnsubscribeMail = lazy(() =>
  import("./jsx/layouts/StoreFront/UnsubscribeMail")
);
const AboutUs = lazy(() => import("../src/jsx/layouts/StoreFront/AboutUs"));
const CategoryPage = lazy(() =>
  import("./jsx/layouts/StoreFront/CategoryPage")
);
const ProductPage = lazy(() => import("./jsx/layouts/StoreFront/ProductPage"));
const NewPlansomPage = lazy(() => import("./jsx/layouts/NewPlansomPage"));
const NewPlansomPage2 = lazy(() => import("./jsx/layouts/LandingPage"));
const LoginWithGooglePage = lazy(() =>
  import("./jsx/layouts/auth/LoginWithGooglePage")
);
const SignUpPage = lazy(() => import("./jsx/layouts/auth/SignUpPage"));
const ResetPasswordPage = lazy(() =>
  import("./jsx/layouts/auth/ResetPasswordPage")
);
const VerifyEmailPage = lazy(() =>
  import("./jsx/layouts/auth/VerifyEmailPage")
);
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));

function App(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!location?.pathname.includes("/messages")) {
      WebSocketInstance.logout();
    }
    if (location?.pathname?.includes("/messages")) {
      WebSocketInstance.connect(
        localStorage.getItem("websocketIds"),
        JSON.parse(localStorage?.getItem("userDetails")).data
      );
    }

    if (
      location?.pathname.includes("/tasks") &&
      (localStorage.getItem("loggedIn") == null ||
        localStorage.getItem("loggedIn") === "null" ||
        localStorage.getItem("loggedIn") === "false")
    ) {
      navigate("/login");
      swal("please login to see task");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      location?.pathname.includes("/goals") &&
      (localStorage.getItem("loggedIn") == null ||
        localStorage.getItem("loggedIn") === "null" ||
        localStorage.getItem("loggedIn") === "false")
    ) {
      navigate("/login");
      swal("please login to see goal");
    }
  }, [location.pathname]);

  const generateCanonicalURL = () => {
    if (window.location.href === "http://localhost:45678/") {
      return "/";
    } else {
      return window.location.href;
    }
  };

  const generatePageTitle = () => {
    const pathSegments = location.pathname.split("/");
    const pageName = pathSegments[pathSegments.length - 1].replace(
      /\.[^/.]+$/,
      ""
    );
    const pageTitle = pageName
      .replace(/-/g, " ")
      .replace(/\b\w/g, (word) => word.toUpperCase());
    const limitedPageTitle =
      pageTitle.length > 30 ? pageTitle.substring(0, 27) + "..." : pageTitle;
    return limitedPageTitle;
  };

  useEffect(() => {
    const canonicalLink = document.querySelector('link[rel="canonical"]');

    if (!canonicalLink) {
      const newCanonicalLink = document.createElement("link");
      newCanonicalLink.rel = "canonical";
      document.head.appendChild(newCanonicalLink);
    }

    document.title = generatePageTitle();
  }, [location.pathname]);

  useEffect(() => {
    document.querySelector('link[rel="canonical"]').href =
      generateCanonicalURL();
  }, [location.pathname, generateCanonicalURL]);

  useEffect(() => {
    if (
      localStorage.getItem("loggedIn") == null ||
      localStorage.getItem("loggedIn") === "null" ||
      localStorage.getItem("loggedIn") === false
    ) {
      localStorage.setItem("loggedIn", false);
    }
    if (
      localStorage.getItem("remember") === null ||
      localStorage.getItem("remember") === "null" ||
      localStorage.getItem("remember") === true
    ) {
      localStorage.setItem("remember", true);
    }
    if (location?.pathname.includes("/unsubscribe-mail")) {
      // navigate('/unsubscribe-mail');
    } else if (location?.pathname?.includes("/users/signup/invitation")) {
      let url = location?.pathname.split("/");
      let email = url[2];
      localStorage.setItem("invite_key", url[6]);
      navigate("/register", { state: { email: email } });
    } else if (location?.pathname.includes("/users/login/invitation")) {
      localStorage.clear();
      let url = location?.pathname.split("/");
      let email = url[2];
      localStorage.setItem("invite_key", url[6]);
      navigate("/sign-in", { state: { email: email } });
    } else if (location.pathname === "/page-register") {
      navigate("/page-register");
    } else if (location.pathname === "/page-forgot-password") {
      navigate("/page-forgot-password");
    } else if (
      localStorage.getItem("loggedIn") === "false" &&
      localStorage.getItem("remember") === "true" &&
      location.pathname === "/login"
    ) {
      navigate("/login");
    } else if (
      localStorage.getItem("remember") === "false" &&
      localStorage.getItem("loggedIn") === "true" &&
      location.pathname === "/login"
    ) {
      navigate("/login");
    } else if (location.pathname === "/store-front") {
      navigate(location.pathname);
    } else if (location.pathname === "/category-page") {
      navigate(location.pathname);
    } else if (location.pathname === "/privacy") {
      navigate(location.pathname);
    } else if (location.pathname === "/terms-and-conditions") {
      navigate(location.pathname);
    } else if (location.pathname === "/cookie-policy") {
      navigate(location.pathname);
    } else if (location.pathname === "/about-us") {
      navigate(location.pathname);
    } else if (location.pathname === "/plansom-partner") {
      navigate(location.pathname);
    } else if (location.pathname === "/contactUs-support") {
      navigate(location.pathname);
    } else if (location.pathname === "/pricing") {
      navigate(location.pathname);
    } else if (
      localStorage.getItem("loggedIn") === "true" &&
      localStorage.getItem("userDetails") &&
      !location.pathname.includes("/product") &&
      !location.pathname.includes("/category") &&
      !location.pathname.includes("/subcategory")
    ) {
      if (
        location.pathname !== "/login" &&
        location.pathname !== "/page-forgot-password" &&
        location.pathname !== "/page-register" &&
        location.pathname !== "/" &&
        location.pathname !== "/store-front" &&
        location.pathname !== "/product-page" &&
        location.pathname !== "/category-page" &&
        location.pathname !== "/privacy" &&
        location.pathname !== "/terms-and-conditions" &&
        location.pathname !== "/cookie-policy" &&
        location.pathname !== "/about-us" &&
        location.pathname !== "/plansom-partner" &&
        location.pathname !== "/contactUs-support" &&
        location.pathname !== "/pricing" &&
        location.pathname !== "/premium-customer-experience-program" &&
        location.pathname !== "/solutions" &&
        location.pathname !== "/sign-up" &&
        location.pathname !== "/sign-in" &&
        location.pathname !== "/page-register" &&
        location.pathname !== "/register" &&
        location.pathname !== "/reset-password" &&
        location.pathname !== "/verify-email"
      ) {
        checkAutoLogin(dispatch, navigate);
      }
    }
    if (location?.pathname.includes("/user-register/success")) {
      navigate("/organization-list");
    } else if (location?.pathname.includes("/invite-user-register/success")) {
      navigate("/home");
    }

    if (location?.pathname.includes("users/partner/signup")) {
      localStorage.clear();
      let url = location?.pathname.split("/");
      let email = url[5];
      localStorage.setItem("invite_keys", url[4]);
      navigate("/page-partner", { state: { email: email, key: url[4] } });
    }
  }, [location.pathname]);

  let routes = (
    <Routes>
      <Route path="/login" element={<LoginWithGooglePage />} title="Login" />
      <Route path="/sign-in" element={<LoginWithGooglePage />} title="Login" />
      <Route
        path="/page-register"
        element={<LoginWithGooglePage />}
        title="Sign In"
      />
      <Route
        path="/sign-in"
        element={<LoginWithGooglePage />}
        title="Sign In"
      />
      <Route path="/register" element={<SignUpPage />} title="Sign Up" />
      <Route
        path="/reset-password"
        element={<ResetPasswordPage />}
        title="Forgot Password"
      />
      <Route
        path="/verify-email"
        element={<VerifyEmailPage />}
        title="Verify Email"
      />
      <Route path="/page-partner" element={<PartnerSignUp />} title="Sign Up" />
      <Route
        path="/page-partner-register"
        element={<PartnerRegistration />}
        title="Sign Up"
      />
      <Route
        path="/page-forgot-password"
        element={<ForgotPassword />}
        title="Forgot Password"
      />
      <Route path="/" element={<NewPlansomPage2 />} title="Home" />
      <Route path="/:plansom" element={<NewPlansomPage2 />} title="Home" />
      <Route path="/plansom-page" element={<NewPlansomPage2 />} title="Home" />
      <Route
        path="/new-plansom-page"
        element={<NewPlansomPage />}
        title="Home"
      />
      <Route
        path="/new-plansom-page-ant-design"
        element={<NewPlansomPage2 />}
        title="Home"
      />
      <Route
        path="/product-page"
        element={<ProductPage />}
        title="Product Page"
      />
      <Route
        exact
        path="/product-page/:id"
        element={<ProductPage />}
        title="Product Page"
      />
      <Route
        path="/category-page"
        element={<CategoryPage />}
        title="Category Page"
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} title="Privacy Policy" />

      <Route path="/category/:categoryName" element={<CategoryPage />} />
      <Route path="/category/:id/:categoryName" element={<CategoryPage />} />
      <Route path="/subcategory/:id/:categoryName" element={<CategoryPage />} />
      <Route
        path="/:categoryName/:id"
        element={<CategoryPage />}
        title="Category Page"
      />
      <Route
        exact
        path="/category-page/:id"
        element={<CategoryPage />}
        title="Category Page"
      />
      {/* <Route path="/privacy" element={<Privacy />} title="Privacy" /> */}
      <Route
        path="/terms-and-conditions"
        element={<TermsAndConditions />}
        title="Terms and Conditions"
      />
      <Route
        path="/terms-conditions"
        element={<TermsAndCondition />}
        title="Terms and Conditions"
      />
      <Route
        path="/cookie-policy"
        element={<CookiePolicy />}
        title="Cookie Policy"
      />
      <Route path="/about-us" element={<AboutUs />} title="About Us" />
      <Route path="/solutions" element={<Solutions />} title="Solutions" />
      <Route path="/pricing" element={<Pricing />} title="Pricing" />
      <Route
        path="/plansom-partner"
        element={<BecomingPlansomPartner />}
        title="Becoming Plansom Partner"
      />
      <Route
        path="/contactUs-support"
        element={<ContacUsAndSupport />}
        title="Contact Us and Support"
      />
      <Route
        path="/unsubscribe-mail"
        element={<UnsubscribeMail />}
        title="Unsubscribe Mail"
      />
      <Route path="/product/:id" element={<ProductPage />} />
      <Route path="/category-page/:id" element={<CategoryPage />} />
    </Routes>
  );

  if (props.isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div
        id="main-wrapper"
        data-testid="index-page"
        className="show"
        style={
          location.pathname === "/store-front"
            ? {
                overflow: "visible",
              }
            : {}
        }
      >
        <div data-testid="main-app">
          <Suspense
            fallback={
              <div id="preloader" data-testid="preloader">
                <div className="sk-three-bounce" data-testid="three-bounce">
                  <div
                    data-testid="bounce"
                    className="sk-child sk-bounce1"
                  ></div>
                  <div
                    data-testid="bounce"
                    className="sk-child sk-bounce2"
                  ></div>
                  <div
                    data-testid="bounce"
                    className="sk-child sk-bounce3"
                  ></div>
                </div>
              </div>
            }
          >
            {routes}
          </Suspense>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default connect(mapStateToProps)(App);
