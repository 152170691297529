import React, { useState, useEffect, memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import ChatRoom from "../Messages/ChatRoom";
import profilePicture from "../../../../src/images/profile/profilePic.png";
import WebSocketInstance from "../../services/WebSocket";
import {
  getAllCountByOrg,
  getAllThreadRoomById,
  readAllMessageByRoomId,
  getThreadDetails,
  getAllChatMessageByThreadID,
  getSearchThreads,
} from "../../../services/messageService";
import { useLocation, useNavigate } from "react-router-dom";
import xeroImage from "../../../images/zero_state_chats_1x.png";
import plogo from "../../../images/PlansomLogo2.png";
import {
  readNotificationConfirmAction, loadMoreThreads, fetchNextThreadChats, allThreadsWithChatMessages, updateThreadList, refreshThreadsList
} from "../../../store/actions/NotificationAction";
import CreateThread from "../Component/CreateThread";
import {
  getThreadList
} from "../../../store/actions/NotificationAction";
import { toast } from "react-toastify";
import { gettingAllThreadByWebsocket } from "../../../store/actions/TaskActions";
import { RiChatNewLine } from "react-icons/ri";
import { CircularProgress } from "@material-ui/core";

const Messages = () => {
  const stateData = useSelector((state) => state);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetails);
  const orgId = localStorage.getItem("organization_id");
  const [orgMember, setOrgMember] = useState([]);
  const [UnreadMessages, setUnreadMessages] = useState();

  const notificationRoom = {
    thread_topic: "Plansom",
    thread_type: "notification",
    unread_messages: UnreadMessages?.notification_counter,
    thread_id: "0000-0000-0000-0000",
  };

  const [selectedUser, setSelectedUser] = useState(
    localStorage.getItem("ActiveChat") === "undefined" || localStorage.getItem("ActiveChat") === null
      ? notificationRoom
      : JSON.parse(localStorage.getItem("ActiveChat"))
  );
  const [selectedMessage, setSelectedMessage] = useState(
    location.state?.message
  );
  const [indexs, setindex] = useState();
  const [model, setModel] = useState(false);
  const [compData, setCompData] = useState("");
  const [isNewThreadCreated, setIsNewThreadCreated] = useState(false);
  const [loadingMoreThreads, setLoadingMoreThreads] = useState(false);
  const [isupdate, setUpdate] = useState(false);
  const [showTableSpinner, setShowTableSpinner] = useState(true);
  const [PlansomRoomState, setPlansomRoomState] = useState(true);
  const [checkModal, setCheckModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const scrollRef = useRef(false);
  const [loadThread, setLoadThread] = useState(false);
  const [markAsRead, setMarkAsRead] = useState(false);
  const [fetchAllList, setFetchAllList] = useState(false);
  const [fetchingFirstTime, setFetchingFirstTime] = useState(true);
  const [loadingSearchedThreads, setLoadingSearchedThreads] = useState(false);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      setLoadingSearchedThreads(true);
      setTimeout(() => {
        getAllThreadRoomByIdData(false, true);
      }, 1000);
    }
  };

  async function getSearchedThreads(loadMoreThreads) {
    setLoadingSearchedThreads(true);
    const start = loadMoreThreads ? orgMember?.length : 0;
    const end = loadMoreThreads ? orgMember?.length + 10 : 25;
    getSearchThreads(orgId, searchText, start, end, userInfoDetails?.data?.accessToken)
      .then((response) => {
        if (loadMoreThreads) {
          let memberData = orgMember
          response?.data?.data.forEach((element) => {
            let addData = true;
            memberData.forEach((ele) => {
              if (ele?.thread_id === element?.thread_id) {
                addData = false;
              }
            });
            addData && memberData.push(element);
          });
          setOrgMember(() => memberData);
          setLoadingSearchedThreads(false);
        } else {
          setOrgMember(response?.data?.data);
          setLoadingSearchedThreads(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching searched thread data:", error);
        setLoadingSearchedThreads(false);
      });
  }

  useEffect(() => {
    if (stateData?.messages?.refreshThreadsList) {
      const resp = readAllMessageByRoomId(
        selectedUser?.thread_id,
        userInfoDetails.data.accessToken
      );
      resp?.then((res) => {
        dispatch(readNotificationConfirmAction([]));
        getAllThreadRoomByIdData();
        setMarkAsRead(false);
      })
      resp?.catch(() => { })
      getMessagesByOrg();
      getAllThreadRoomByIdData();
      dispatch(refreshThreadsList(false));
    }
  }, [stateData?.messages?.refreshThreadsList]);

  useEffect(() => {
    if (searchText !== "") {
      const debounceTimer = setTimeout(() => {
        getSearchedThreads(); // Call your function
      }, 1000);
      return () => clearTimeout(debounceTimer); // Clear timer on cleanup
    }
  }, [searchText]);


  useEffect(() => {
    setUnreadMessages(UnreadMessages);
  }, [UnreadMessages]);

  useEffect(() => {
    if (localStorage.getItem('previousLocation') === '/stripe' && sessionStorage.getItem("strategyImported") === "false") {
      toast.info(
        <div className="m-1">
          <h3 >Payment Pending for Strategy </h3>

        </div>
        ,
        { toastId: 'strategyPaymentFail' })
      sessionStorage.removeItem('strategyImported')
    }

    if (localStorage.getItem("ActiveChat") === "undefined" || localStorage.getItem("ActiveChat") === null)
      localStorage.setItem("ActiveChat", JSON.stringify(notificationRoom));
  }, []);

  const onScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    setLoadThread(true);
    const threshold = 5;

    if (scrollTop + clientHeight >= scrollHeight - threshold) {
      setLoadingMoreThreads(true);
      if (orgMember?.length >= stateData.messages.fetchNextThreadChats && !stateData?.messages?.loadMoreThreads && !searchText) {
        dispatch(fetchNextThreadChats(stateData.messages.fetchNextThreadChats + 10))
        dispatch(loadMoreThreads(true));
        loadThread && getAllThreadRoomByIdData(true);
      } else if (searchText) {
        dispatch(loadMoreThreads(true));
        getSearchedThreads(true);
      }
      setTimeout(() => {
        setLoadingMoreThreads(false);
      }, 1000)
    }
  };

  useEffect(() => {
    if (selectedUser?.thread_id !== location.state?.selectedUser?.thread_id) {
      SelectedUser(selectedUser);
    } else {
      setSelectedUser(() => selectedUser);
      localStorage.setItem("ActiveChat", JSON.stringify(selectedUser));
    }
  }, [selectedUser]);

  useEffect(() => {
    setPlansomRoomState(PlansomRoomState);
  }, [PlansomRoomState]);

  useEffect(() => {
    setOrgMember(() => orgMember);
    if (orgMember?.length <= 10) {
      dispatch(fetchNextThreadChats(0))
    }
  }, [orgMember]);

  useEffect(() => {
    if (!model) {
      if (stateData?.messages?.threadList?.data?.message === "Chat thread found successfully") {
        receivedThreads(stateData?.messages?.threadList)
      }
      else if (stateData?.messages?.threadList === undefined || stateData?.messages?.threadList.length === undefined && !loadThread) {
        getAllThreadRoomByIdData();
      }
      setModel(() => model);
    }
  }, [model, stateData?.messages?.threadList?.data?.message, stateData?.messages?.threadList]);

  useEffect(() => {
    if (stateData?.messages?.updateThreadList === true) {
      setFetchAllList(true);
      setTimeout(() => {
        getAllThreadRoomByIdData();
      }, 300)
      dispatch(updateThreadList(false));
    }
  }, [stateData]);

  useEffect(() => {
    if (location.state?.selectedUser?.thread_id) {
      if (location?.state?.message) {
        if (stateData?.messages?.threadList) {
          if (location?.state?.selectedUser?.thread_id === JSON.parse(localStorage.getItem("ActiveChat"))?.thread_id) {
            getAllThreadRoomByIdData()
          } else {
            receivedThreads(stateData?.messages?.threadList)
          }
        } else {
          getAllThreadRoomByIdData()
        }
      }
      if (stateData?.messages?.threadList === undefined || stateData?.messages?.threadList === null) {
        getAllThreadRoomByIdData();
      }
    }
  }, [location.state?.selectedUser]);


  useEffect(() => {
    if (stateData?.task?.gettingAllThreadStatus == true) {
      setIsNewThreadCreated(true);
      getAllThreadRoomByIdData();
      getMessagingList()
      dispatch(gettingAllThreadByWebsocket(false))
    }
  }, [stateData?.task?.gettingAllThreadStatus])

  useEffect(() => {
    setSelectedUser(selectedUser);
    if (
      selectedUser?.thread_id !== undefined &&
      selectedUser?.thread_id !== "0000-0000-0000-0000" &&
      selectedUser?.thread_type !== "notification"
    ) {
      localStorage.setItem("ActiveChat", JSON.stringify(selectedUser));
      if (WebSocketInstance?.socketRef?.url.split("/")[5] != selectedUser?.thread_id)
        if (location?.state?.selectedUser?.thread_id) {
          WebSocketInstance.connect(
            location?.state?.selectedUser?.thread_id,
            JSON.parse(localStorage?.getItem("userDetails")).data
          );
        } else {
          WebSocketInstance.connect(
            selectedUser?.thread_id,
            JSON.parse(localStorage?.getItem("userDetails")).data
          );
        }
      setindex(() => indexs);
    }
  }, [selectedUser?.thread_id]);

  async function getAllThreadRoomByIdData(text = false, searchTextCleared) {
    const start = text || loadThread ? markAsRead ? 0 : orgMember?.length : 0;
    const end = text || loadThread ? markAsRead ? orgMember?.length : orgMember?.length + 10 : fetchingFirstTime ? 25 : 10;
    if (stateData?.messages?.threadList === undefined || stateData?.messages?.threadList.length === undefined) {
      const data = getAllThreadRoomById(
        localStorage.getItem("organization_id"),
        fetchAllList ? 0 : searchTextCleared ? 0 : start,
        fetchAllList ? orgMember?.length : searchTextCleared ? 25 : end,
        userInfoDetails?.data?.accessToken
      );
      data?.then(
        await ((res) => {
          receivedThreads(res, text, searchTextCleared);
        })
      );
      data?.catch((err) => {
      });
    } else if (stateData?.messages?.threadList?.data?.message === "Chat thread found successfully") {
      receivedThreads(stateData?.messages?.threadList)
    }

  }

  const receivedThreads = (res, text, searchTextCleared) => {
    setShowTableSpinner(() => false);
    let memberData = searchTextCleared
      ? []
      : text || loadThread
        ? fetchAllList
          ? []
          : orgMember
        : [];
    res?.data?.data.forEach((element) => {
      let addData = true;
      memberData.forEach((ele) => {
        if (ele?.thread_id === element?.thread_id) {
          addData = false;
        }
      });
      addData && memberData.push(element);
    });
    if (isNewThreadCreated) {
      setTimeout(() => {
        if (selectedUser?.thread_id !==
          res.data.data[0]?.thread_id) {
          SelectedUser(res.data.data[0]);
          setindex(0);
        }
      }, 2000)
      setIsNewThreadCreated(false);
    }
    setOrgMember(() => memberData);
    setFetchingFirstTime(false);
    setLoadingSearchedThreads(false);
    setFetchAllList(false);
    res?.data?.data?.map((element, index) =>
      element.plansom
        ? setPlansomRoomState(() => false)
        : setPlansomRoomState(() => true)
    );
    if (location.state?.selectedUser?.thread_id) {
      res?.data?.data?.forEach((element, index) => {
        if (
          location.state?.selectedUser?.thread_id === element?.thread_id
        ) {
          setSelectedMessage(location.state?.message);
          SelectedUser(element);
          setindex(index);
          localStorage.setItem("ActiveChat", JSON.stringify(element));
          setTimeout(() => {
            setSelectedMessage("");
            navigate("/messages", { state: {} });
          }, 1000);
        }
      });
      if (
        JSON.parse(localStorage.getItem("ActiveChat"))?.thread_id !==
        location.state?.selectedUser?.thread_id
      ) {
        getThreadDetailsByID(location.state?.selectedUser?.thread_id);
        return;
      }

    } else if (
      JSON.parse(localStorage.getItem("ActiveChat"))?.thread_id
      !==
      "0000-0000-0000-0000"
    ) {
      res?.data?.data?.forEach((element) => {
        if (
          JSON.parse(localStorage.getItem("ActiveChat"))?.thread_id ==
          element?.thread_id && (JSON.parse(localStorage.getItem("ActiveChat"))?.thread_id !== undefined && element?.thread_id !== undefined)
        ) {
          SelectedUser(element);
          localStorage.setItem(
            "ActiveChat",
            JSON.stringify(JSON.parse(localStorage.getItem("ActiveChat")))
          );
        }
      });
    } else {
      setSelectedUser(() => notificationRoom);
      localStorage.setItem("ActiveChat", JSON.stringify(notificationRoom));
    }
  }

  useEffect(() => {
    if (isupdate && compData?.length !== 0) {
      if (compData === "delete") {
        localStorage.removeItem("ActiveChat");
        setSelectedUser(() => notificationRoom);
        localStorage.setItem("ActiveChat", JSON.stringify(notificationRoom));
        setCompData(() => "");
      }
      if (compData === "member") {
        setCompData(() => "");
      }
      getMessagesByOrg();
    }

    if (compData === "update" && selectedUser?.thread_id === "0000-0000-0000-0000") {
      dispatch(readNotificationConfirmAction([]));
      getMessagingList();
      getAllThreadRoomByIdData();
    }
    setUpdate(() => false);
  }, [isupdate]);

  useEffect(() => {
    if (markAsRead && selectedUser?.unread_messages > 0) {
      const resp = readAllMessageByRoomId(
        selectedUser?.thread_id,
        userInfoDetails.data.accessToken
      );
      resp?.then((res) => {
        dispatch(readNotificationConfirmAction([]));
        getAllThreadRoomByIdData();
        setMarkAsRead(false);
      })
      resp?.catch(() => { })
    } else {
      setMarkAsRead(false);
    }
  }, [markAsRead])

  useEffect(() => {
    getMessagesByOrg();
    getAllThreadRoomByIdData();
  }, [])

  const isUpdate = (data) => {
    setCompData(data);
    setUpdate(true);
  };

  const SelectedUser = (element) => {
    if (selectedUser?.thread_id !== element?.thread_id) {
      setMarkAsRead(true);
      setSelectedUser(element);
      if (selectedUser !== element) {
        if (localStorage.getItem("ActiveChat") !== "null") {
          if (
            location.state?.selectedUser?.thread_id == element?.thread_id
          ) {
            // console.log('selectedUser55 10', element, location?.state?.selectedUser?.thread_id)
          } else {
            WebSocketInstance.close();
          }
        }
        setSelectedUser(element);
        localStorage.setItem("ActiveChat", JSON.stringify(element));
        if (element.thread_type !== "notification") {
          setUpdate(!isupdate);
        } else {
          setSelectedUser(element);
          localStorage.setItem("ActiveChat", JSON.stringify(notificationRoom));
        }
      }
    }
    else {
      setSelectedUser(element);
      localStorage.setItem("ActiveChat", JSON.stringify(element));
    }
  };

  async function getMessagesByOrg() {
    const data = getAllCountByOrg(orgId, userInfoDetails?.data?.accessToken);
    data?.then(
      await ((res) => {
        setUnreadMessages(() => res?.data?.data);
      })
    );
    data?.catch((err) => { });

  }
  // const navigateC = useNavigate()
  const ClickOnForward = () => {
    setCheckModal(false)
  }

  const ClickOnBack = () => {
    if (localStorage.getItem("gotoChatkey") === "false") {
      navigate(localStorage.getItem("previousLocation"));
      localStorage.setItem("gotoChatkey", false);
    } else if (
      selectedUser?.task &&
      localStorage.getItem("gotoChatkey") === "true"
    ) {
      navigate("/tasks", { state: { task: selectedUser?.task } });
      localStorage.setItem("gotoChatkey", false);
    } else if (
      selectedUser?.goal &&
      localStorage.getItem("gotoChatkey") === "true"
    ) {
      navigate("/goals", { state: { goal: selectedUser?.goal, key: true } });
      localStorage.setItem("gotoChatkey", false);
    }
  };

  const getMessagingList = () => {
    const start = 0;
    const end = orgMember?.length;
    dispatch(getThreadList(
      localStorage.getItem("organization_id"),
      start,
      end,
      userInfoDetails.data.accessToken
    ));
  }

  const getThreadDetailsByID = async (id) => {
    try {
      const data = await getThreadDetails(
        id,
        userInfoDetails?.data?.accessToken
      );
      setSelectedMessage(location.state?.message);
      SelectedUser(data.data.data);
      updateMessagesOfActiveThread(id);
      setindex(0);
      localStorage.setItem("ActiveChat", JSON.stringify(data.data.data));
      setTimeout(() => {
        setSelectedMessage("");
        navigate("/messages", { state: {} });
      }, 1000);
      return data.data.data; // Return the resolved data
    } catch (err) {
      console.error("error in thread details ", err);
      throw err; // Rethrow the error to handle it in the caller
    }
  };

  const updateMessagesOfActiveThread = (id) => {
    getAllChatMessageByThreadID(
      id,
      0,
      10,
      userInfoDetails.data.accessToken
    )
      .then((data) => {

        const conversations = stateData.messages.allThreadsWithChatMessages.flatMap((data) => {
          if (data?.data?.data) {
            return data.data.data;
          }
          else {
            return data;
          }
          return [];
        });

        const receivedMessageThread = conversations?.find(
          (thread) => thread?.conversation[0]?.thread?.thread_id === id
        );

        const receivedMessageThreadIndex = conversations.findIndex(
          (thread) => thread?.conversation[0]?.thread?.thread_id === receivedMessageThread?.conversation[0].thread.thread_id
        );

        if (receivedMessageThreadIndex !== -1) {
          // Replace the active thread data with PrevMessage
          const updatedConversation = {
            ...conversations[receivedMessageThreadIndex],
            conversation: data?.data.data.conversation, // Replace conversation with PrevMessage
          };

          // Update the conversations array
          const updatedConversations = [...conversations];
          updatedConversations[receivedMessageThreadIndex] = updatedConversation;

          // Dispatch the updated conversations to Redux
          dispatch(allThreadsWithChatMessages(updatedConversations));

        }

      })
      .catch((error) => {
        console.error("Error fetching chat messages for member:", error);
      });

  }

  const handleNewMessageNotification = (msg, threadId, senderIds) => {
    if (Notification.permission === 'granted' && senderIds !== msg.sender_id) {
      const title = `New message in ${msg?.thread_topic || 'Unknown Thread'} from ${msg?.sender_name || ''}`;
      const body = msg.message;
      new Notification(title, { body });
    }
  };

  const renderThreadList = () => {
    return (
      <div className="col-sm-3 p-0 m-0" style={{ display: checkModal ? 'none' : '' }}>
        <div className="chat-sidebar px-3 m-0 pr-0 mt-3" id="chatSidebar">
          <div className="recent-msg chat-bx py-0">
            <div className="d-flex justify-content-between align-items-center py-0">
              {/* <span className="h2 mx-1">Messages</span> */}
              <input
                type="text"
                placeholder="Search threads..."
                value={searchText}
                onChange={handleSearchChange}
                className="form-control"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "30px",
                  padding: "5px 15px",
                  width: "80%",
                  transition: "border-color 0.3s", // Smooth transition for border color
                  float: 'left'
                }}
              />
              <div className="d-flex justify-content-between align-items-center">
                <RiChatNewLine
                  onClick={(e) => {
                    e.preventDefault();
                    setModel(true);
                  }}
                  title="New thread"
                  color={"#356fd4"}
                  size={24}
                  cursor={"pointer"}
                />
              </div>
            </div>
          </div>

          <PerfectScrollbar
            className="dlab-scroll mt-3"
            style={{ height: "60vh" }}
            containerRef={(el) => {
              scrollRef.current = el;
            }}
            onScroll={() => onScroll()}
            option={{ suppressScrollX: true }}
          >
            {!loadingSearchedThreads ?
              <div className="">
                {orgMember?.map((element, index) => (
                  <>
                    {element.plansom ? (
                      <div className="border-bottom" index>
                        <div
                          className="chat-bx my-1"
                          style={
                            selectedUser?.thread_topic !== "Plansom"
                              ? { cursor: "pointer" }
                              : {
                                backgroundColor: "#F3F4F6",
                                borderRadius: "10px",
                              }
                          }
                          key={index}
                        >
                          <div
                            className="px-1 d-flex"
                            key={index}
                            onClick={(e) => {
                              selectedUser?.thread_id !== "" &&
                                SelectedUser(notificationRoom);
                              setindex(index);
                              localStorage.setItem(
                                "ActiveChat",
                                JSON.stringify(notificationRoom)
                              );
                              setCheckModal(window.innerWidth <= 574)
                            }}
                          >
                            <div className="chat-img">
                              <img
                                src={plogo}
                                alt="chat-img"
                                title="chat-img"
                                className="pb-2"
                              />
                            </div>
                            <div className="w-100">
                              <div className="d-flex mb-1 align-items-center pt-2">
                                <span
                                  className={
                                    selectedUser?.thread_id !==
                                      element?.thread_id
                                      ? "h4 ms-2 "
                                      : ""
                                  }
                                >
                                  <span
                                    style={
                                      selectedUser?.thread_topic !==
                                        "Plansom"
                                        ? { fontWeight: 500 }
                                        : {}
                                    }
                                  >
                                    {notificationRoom.thread_topic}
                                  </span>
                                </span>
                              </div>
                            </div>
                            <Link to={"#"} className="p-0 m-0">
                              <span className="fs-12 ms-auto badge badge-secondary">
                                {notificationRoom.unread_messages !== 0
                                  ? notificationRoom.unread_messages
                                  : ""}
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="border-bottom" index>
                        <div
                          className="chat-bx my-1"
                          style={
                            selectedUser?.thread_id !==
                              element?.thread_id
                              ? { cursor: "pointer" }
                              : {
                                backgroundColor: "#F3F4F6",
                                borderRadius: "10px",
                              }
                          }
                          key={index}
                        >
                          <div
                            className="px-1 d-flex"
                            key={index}
                            onClick={(e) => {
                              updateMessagesOfActiveThread(element?.thread_id);
                              selectedUser?.thread_id !==
                                element?.thread_id &&
                                SelectedUser(element);
                              setindex(index);
                              setCheckModal(window.innerWidth <= 574)
                            }}
                          >
                            <div
                              className="d-flex m-0"
                              style={{ maxWidth: "70px" }}
                            >
                              <div className="chat-img">
                                {element?.thread_type === "personal" ? (
                                  <img
                                    src={element?.thread_image
                                      ? element?.thread_image
                                      : element?.initiator?.profile_picture
                                        ? element?.initiator?.profile_picture
                                        : profilePicture}
                                    alt="chat-img"
                                    title="chat-img"
                                    className="plansom-notification-img"
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      // padding: "2px",
                                      cursor: "pointer",
                                      // maxWidth: "100%", // Make the image responsive
                                    }}
                                  />
                                ) : element?.thread_image ? (
                                  <img
                                    src={element?.thread_image}
                                    alt="thread-img"
                                    title="thread-img"
                                    className="plansom-notification-img"
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      // padding: "2px",
                                      cursor: "pointer",
                                      // maxWidth: "100%", // Make the image responsive
                                    }}
                                  />
                                ) : element?.initiator?.profile_picture ? (
                                  <img
                                    src={element?.initiator?.profile_picture}
                                    alt="thread-img"
                                    title="thread-img"
                                    className="plansom-notification-img"
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      // padding: "2px",
                                      cursor: "pointer",
                                      // maxWidth: "100%", // Make the image responsive
                                    }}
                                  />
                                ) : (
                                  <i
                                    className="fa fa-users"
                                    aria-hidden="true"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "30px",
                                      color: "#878484",
                                    }}
                                  ></i>
                                )}
                              </div>

                              <span
                                className="chat-img mt-4"
                                style={{ left: "-30px" }}
                              ></span>
                            </div>
                            <div className="w-100">
                              <div className="d-flex mb-1 align-items-center">
                                <span className="h4 ms-2 topic-name">
                                  <span
                                    style={
                                      selectedUser?.thread_id !==
                                        element?.thread_id
                                        ? { fontWeight: "500" }
                                        : {}
                                    }
                                  >
                                    {element?.thread_topic && (element?.thread_topic.length > 15 && window.screen.width <= 768 ? `${element?.thread_topic.substring(0, 15)}...` : element?.thread_topic)}

                                    {/* {element?.thread_topic.length > 15 && window.screen.width<=768 ? `${element?.thread_topic.substring(0, 15)}...` : element?.thread_topic} */}
                                    {/* {element?.thread_topic.substring(0, 15)}...`  */}
                                  </span>
                                </span>
                                {element?.thread_type === "group" && (
                                  <span
                                    className="float-end ms-auto"
                                    style={{ color: "#bfbfbf" }}
                                  >
                                    <i
                                      className="fa fa-users"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                )}
                                <span className="fs-12 ms-1 badge badge-secondary">
                                  {element?.unread_messages !== 0
                                    ? element?.unread_messages
                                    : ""}
                                </span>
                              </div>
                              {typeof element?.last_message ===
                                "string" ? (
                                <div className="d-flex px-2">
                                  <img
                                    src={
                                      element?.last_message_sender
                                        ?.profile_picture ||
                                      profilePicture
                                    }
                                    alt="last-msg"
                                    title="last-msg"
                                    style={{
                                      height: "25px",
                                      width: "25px",
                                      padding: "2px",
                                      cursor: "pointer",
                                      borderRadius: "50%",
                                    }}
                                  />
                                  <span className="p fs-12 font-w200 pt-1">
                                    {element?.last_message?.substr(
                                      0,
                                      25
                                    ) + "  ..."}
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div> :
              <div className="">
                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <CircularProgress size={40} />
                </div>
              </div>
            }
            {loadingMoreThreads && !loadingSearchedThreads &&
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px"
                }}
              >
                <CircularProgress size={30} />
              </div>
            }
          </PerfectScrollbar>
        </div>
      </div>
    );
  };

  const renderChatWindow = () => {
    if (!selectedUser) {
      return (
        <div className="col-sm-9">
          <div className="col text-center mt-5 pt-5">
            <img
              src={xeroImage}
              alt="conversation"
              title="conversation"
              style={{
                backgroundSize: "250px",
                height: "250px",
                width: "250px",
                opacity: "0.7",
              }}
            />
            <h3>Choose a conversation</h3>
            <span>
              Click on an existing chat or click
              <u>
                <Link
                  style={{ cursor: "pointer", color: "red" }}
                  to={""}
                  onClick={() => {
                    setModel(() => true);
                  }}
                >
                  &nbsp;here&nbsp;
                </Link>
              </u>
              to create a new conversation.
            </span>
          </div>
        </div>
      );
    }

    return (
      <div
        className={`col-sm-9 ${window.innerWidth <= 574 && !checkModal ? 'd-none' : ''} chat-body`}
        style={{ height: "100%" }}
        key={indexs}
      >
        <ChatRoom
          UnreadMessages={UnreadMessages}
          user={selectedUser}
          message={selectedMessage}
          handler={isUpdate}
          orgMember={orgMember}
        />
      </div>
    );
  };

  return (
    <>
      {model && <CreateThread model={model} setModal={setModel} />}
      <div className='row group-list'>
        {/* Back navigation button */}
        {localStorage.getItem("previousLocation") !== localStorage.getItem("currentLocation") && (
          <span className="">
            {!window.innerWidth <= 574 && !checkModal ? (
              <i
                className="fa fa-reply fs-20"
                title={
                  !localStorage.getItem("gotoChatkey")
                    ? `Navigate to ${localStorage.getItem("previousLocation").split("/")[1]}`
                    : selectedUser?.task
                      ? "Navigate back to tasks"
                      : "Navigate back to goals"
                }
                style={{ cursor: "pointer" }}
                onClick={ClickOnBack}
              />
            ) : (
              <i
                className="fa fa-reply fs-20"
                style={{ cursor: "pointer" }}
                onClick={ClickOnForward}
              />
            )}
          </span>
        )}

        <div className="card mb-2 message-card h-100">
          <div className="card-body py-3 px-0">
            {showTableSpinner ? (
              <center data-testid="loading-spinner">
                <div style={{ padding: "20px", margin: "300px" }}>
                  <center>
                    <div style={{ padding: "20px" }}></div>
                    <div
                      className="loading-dots--dot mx-2"
                      style={{
                        height: "25px",
                        width: "25px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <div
                      className="loading-dots--dot"
                      style={{
                        height: "25px",
                        width: "25px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <div
                      className="loading-dots--dot mx-2"
                      style={{
                        height: "25px",
                        width: "25px",
                        borderRadius: "50%",
                      }}
                    ></div>
                  </center>
                </div>
              </center>
            ) : (
              <div className="row p-0 m-0">
                {renderThreadList()}
                {renderChatWindow()}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Messages);


















