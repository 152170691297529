import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Image, Input, Layout, Row, Typography } from 'antd';
import { PiTextTBold } from "react-icons/pi";
import PromptLogo from "../../../images/Plansom/prompt.png";
import GeneratePlanButtonLogo from "../../../images/Plansom/generatePlanButton.png";
import ChatModal from '../../layouts/ChatModal';

const { Content } = Layout;
const { Paragraph } = Typography;

const contentStyle = {
  textAlign: 'center',
  minHeight: 50,
  background: "linear-gradient(to right, #3867FF4D, #ffffff, #3867FF4D)",
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none'
};

const layoutStyle = {
  height: "73.5vh",
};

const prompts = [
  {
    title: "Product Strategy",
    prompt: "I want to enhance our product strategy to boost market fit. Generate a plan with steps for positioning and feature development."
  },
  {
    title: "Increase Sales",
    prompt: "I’m looking to increase sales by 10% in the next 3 months. Generate a strategy focusing on online channels and actionable growth steps."
  },
  {
    title: "Enhance Branding",
    prompt: "My goal is to strengthen our brand. Create a plan for building a unique brand voice and visual identity."
  },
  {
    title: "Build a Website",
    prompt: "I need a plan for building a user-friendly website that maximizes UX and showcases key features effectively."
  },
  {
    title: "Enhance Customer Engagement",
    prompt: "Looking to increase customer engagement over the next quarter. Generate steps for interactive content and retention strategies."
  },
  {
    title: "Improve Operational Efficiency",
    prompt: "I aim to improve operational efficiency. Provide a step-by-step plan to streamline workflows and introduce automation."
  },
  {
    title: "Expand into New Markets",
    prompt: "Planning to expand into new geographic markets. Create an entry plan, including research and regional adaptation steps."
  },
  {
    title: "Launch a New Product",
    prompt: "Preparing for a product launch targeting millennials. Generate a go-to-market plan with pre-launch and digital outreach steps."
  },
]

const NewPlansomPage2 = () => {
  const [preDefinedInput, setPreDefinedInput] = useState("");
  const [openChatModal, setOpenChatModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);



  const [socketRef, setSocketRef] = useState(null);
  const [stateSocketRef, setStateSocketRef] = useState(null);
  const [isConnected, setIsConnected] = useState(false);


  function generateUniqueUID() {
    const timestamp = Date.now();
    const randomNum = Math.floor(Math.random() * 10000);
    return `${timestamp}${randomNum}`;
  }

  function getOrCreateUID() {
    let storedUID = localStorage.getItem('plansom_bot_id');
    if (!storedUID) {
      storedUID = generateUniqueUID();
      localStorage.setItem('plansom_bot_id', storedUID);
    }
    return storedUID;
  }
  
  const establishWebSocketConnection = () => {
    console.log("23456789087654s")
    if (!socketRef) {
      console.log("Establishing WebSocket connection...");
      const socket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/ws/plansombot/${getOrCreateUID()}`);
      const stateSocket =  new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/ws/plansombot/thread/state/notification/${getOrCreateUID()}`);
      
      socket.onopen = () => {
        console.log("WebSocket is connected");
        setIsConnected(true);
        setSocketRef(socket);
      };

      
      socket.onclose = () => {
        console.log("WebSocket is closed");
        setIsConnected(false);
        setSocketRef(null);
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        setSocketRef(null);
      };

      stateSocket.onopen = () => {
        console.log("State WebSocket is connected");
        setIsConnected(true);
        setStateSocketRef(stateSocket);
      };
      
      
      stateSocket.onclose = () => {
        console.log("State WebSocket is closed");
        setIsConnected(false);
        setStateSocketRef(null);
      };

      stateSocket.onerror = (error) => {
        console.error("State WebSocket error:", error);
        setStateSocketRef(null);
      };

      // Keep reference to socket
      setSocketRef(socket);
      setStateSocketRef(stateSocket);

    }
  };


  useEffect(() => {
      if (!socketRef) {
        establishWebSocketConnection();  
      }
  }, [socketRef]);
  
  
 
  const handleInputChange = (e) => {
    setPreDefinedInput(e.target.value);
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (preDefinedInput) {
        setOpenChatModal(true);
      };
    }
  };

  useEffect(() => {
    if (preDefinedInput) {
      document.addEventListener("keypress", handleKeyPress);
    }
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [preDefinedInput]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'hoempage',
    });
  }, [])

  return (
    <>
      {!openChatModal ?
        <Layout style={layoutStyle}>
          <Layout>
            <Content style={contentStyle}>
              <Row style={{ marginBottom: "2%", marginTop: "2%" }}>
                <Col span={12} offset={6} style={{}} >
                  <Input
                    style={{ borderRadius: "40px", padding: "10px" }}
                    prefix={
                      <PiTextTBold size={28} color="#90939B" style={{ margin: "5px" }} />
                    }
                    suffix={
                      <Button variant="contained" style={{ backgroundColor: "#1D3BAF", borderRadius: "20px", color: "#ffffff", textTransform: "none", padding: "20px" }}
                        icon={
                          <Image
                            width={24}
                            src={GeneratePlanButtonLogo}
                          />
                        }
                        disabled={!preDefinedInput}
                        onClick={() => {
                          if (preDefinedInput) {
                            setOpenChatModal(true);
                          }
                        }}
                      >
                        Generate Plan
                      </Button>
                    }
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        if (preDefinedInput) {
                          setOpenChatModal(true);
                        }
                      }
                    }}
                    value={preDefinedInput}
                    onChange={(e) => { handleInputChange(e) }}
                    placeholder="Build a business plan for a restaurant..." />
                </Col>
              </Row>
              <Row style={{ marginBottom: "50px" }}>
                <Col span={12} offset={6} >
                  <Card
                    bordered={true}
                    style={{
                      borderRadius: "20px",
                      border: "2px solid #080D210A"
                    }}
                  >
                    <Row align="middle" justify="space-between" wrap={false}>
                      <Col xs={24} sm={12} md={8} style={{ textAlign: 'left' }}>
                        <Paragraph style={{ fontFamily: "Open Sans", fontWeight: 400, fontSize: "16px", lineHeight: "24px", color: "#080D21" }}>
                          Or try our prompts:
                        </Paragraph>
                      </Col>
                    </Row>
                    <Row align="middle" justify={isExpanded ? "start" : "space-between"} wrap={true} gutter={[16, 16]} style={{ display: "flex", justifyContent: isExpanded ? "start" : "space-between" }}>
                      {prompts.slice(0, isExpanded ? prompts.length : 4).map((prompt, index) => (
                        <Button key={index} shape='round' style={{ backgroundColor: "#ffffff", textTransform: "none", display: "inline-flex", alignItems: "center", justifyContent: "center", height: "auto", padding: "10px", margin: "5px", width: "auto" }}
                          icon={<Image width={24} src={PromptLogo} preview={false} />}
                          onClick={() => { // Check if not already connecting
                            setPreDefinedInput(prompt.prompt);
                            establishWebSocketConnection()
                          }
                        }
                        >
                          <span style={{ fontFamily: "Open Sans", fontWeight: 400, fontSize: "16px", lineHeight: "24px", color: "#080D21", marginRight: 15 }}>
                            {prompt.title}
                          </span>
                        </Button>
                      ))}
                    </Row>
                    <Button type='text' onClick={() => setIsExpanded(!isExpanded)} style={{ marginTop: "10px", backgroundColor: "transparent", border: "none", color: "#1D3BAF" }}>
                      {isExpanded ? "Show Less" : "Show More"}
                    </Button>
                  </Card>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout> :
        <ChatModal openChatModal={openChatModal} setOpenChatModal={setOpenChatModal} preDefinedInput={preDefinedInput}
        setPreDefinedInput={setPreDefinedInput} existingSocket={socketRef} existingStateSocket={stateSocketRef}
        value={isConnected} reConnectWebSocket={setSocketRef}

        />
      }
    </>
  );
}

export default NewPlansomPage2;
