import swal from "sweetalert";
import {
  formatError,
  login,
  saveTokenInLocalStorage,
  signUp,
  forgotpass,
  verifyOTP,
  resetPassword,
  verifySignupOTP,
  resendOTPSignup,
  getUserdetails,
  inviteUser,
  logoutUser,
  PartenerSignIn,
} from "../../services/AuthService";
import CryptoJS from "crypto-js";
import Enc_Key from "../../jsx/config/index";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const FORGOT_CONFIRMED_ACTION =
  "[forgotpass action] confirmed forgotpass";
export const FORGOT_FAILED_ACTION = "[forgotpass action] failed forgotpass";
export const CHANGE_PASSWORD_CONFIRMED_ACTION =
  "[resetPassword action] confirmed resetPassword";
export const CHANGE_PASSWORD_FAILED_ACTION =
  "[resetPassword action] failed resetPassword";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const VERIFY_OTP_CONFIRMED_ACTION =
  "[verifyOTP action] confirmed verifyOTP";
export const VERIFY_OTP_FAILED_ACTION = "[verifyOTP action] failed verifyOTP";
export const VERIFY_OTP_SIGNUP_CONFIRMED_ACTION =
  "[verifyOTPSignup action] confirmed verifyOTPSignup";
export const VERIFY_OTP_SIGNUP_FAILED_ACTION =
  "[verifyOTPSignup action] failed verifyOTPSignup";
export const RESEND_OTP_CONFIRMED_ACTION =
  "[resendOTP action] confirmed resendOTP";
export const RESEND_OTP_FAILED_ACTION = "[resendOTP action] failed resendOTP";
export const USER_DETAILS_FAILED_ACTION =
  "[getUserdetails action] failed getUserdetails";
export const USER_DETAILS_CONFIRM_ACTION =
  "[getUserdetails action] confirmed getUserdetails";
export const INVITE_USER_CONFIRMED_ACTION =
  "[inviteUser action] confirmed inviteUser";
export const INVITE_USER_FAILED_ACTION =
  "[inviteUser action] failed inviteUser";
export const INVITE_PARTNER_CONFIRMED_ACTION =
  "[invitePartner action] confirmed invitePartner";
export const INVITE_PARTNER_FAILED_ACTION =
  "[invitePartner action] failed invitePartner";

const salt = Enc_Key?.Enc_Key;

export function signupAction(
  first_name,
  last_name,
  email,
  password,
  discountCode,
  timezone,
  history
) {
  return (dispatch) => {
    return signUp(first_name, last_name, email, password, discountCode, timezone)
      .then((response) => {
        if (response?.data?.data?.user_role === 'owner') {
          localStorage.setItem("scope", "personal");
        } else {
          localStorage.setItem("scope", "personal");
        }

        localStorage.removeItem("invite_key");
        dispatch(confirmedSignupAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.response?.data);
        dispatch(signupFailedAction(errorMessage));
        throw error; // Re-throw the error to ensure it can be caught in onSubmit
      });
  };
}


export function resendOTP(email) {
  return (dispatch) => {
    resendOTPSignup(email)
      .then((response) => {
        dispatch(confirmedresendOTPAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(resendOTPFailedAction(errorMessage));
      });
  };
}

export function logout(navigate, emails = null) {
  if (localStorage.getItem("userDetails")) {
    localStorage.setItem("loggedIn", false);
    logoutUser(
      JSON.parse(localStorage.getItem("userDetails"))?.data?.accessToken
    );
  }
  localStorage.removeItem("strategyStatus");
  localStorage.removeItem("userDetails");
  localStorage.removeItem("organization_id");
  localStorage.removeItem("org_access");
  localStorage.removeItem("scope");
  localStorage.removeItem("team_id");
  localStorage.removeItem("startDate");
  localStorage.removeItem("endDate");
  localStorage.removeItem("timescope");
  localStorage.removeItem("ActiveChat");
  localStorage.removeItem("noOfDays");
  localStorage.removeItem("previousLocation");
  localStorage.removeItem("currentLocation");
  localStorage.removeItem("openChatbot");
  localStorage.removeItem("store");
  localStorage.removeItem("csvGoal");
  localStorage.removeItem("scheduled_tasks_row_per_page");
  localStorage.removeItem("history_tasks_row_per_page");
  localStorage.removeItem("goals_row_per_page");
  localStorage.removeItem("OnboardingSkeleton");
  localStorage.removeItem("progressPercent");
  localStorage.removeItem("lastProgressUpdated");

  if (
    localStorage.getItem("remember") === "true" &&
    localStorage.getItem("loggedIn") === "true" &&
    localStorage.getItem("AutoLogin") === "true"
  ) {
    return (dispatch) => {
      let email = CryptoJS.AES.decrypt(localStorage.getItem("userEmail"), salt)
        ?.toString(CryptoJS.enc.Utf8)
        .replace('"', "")
        .replace('"', "");
      let pass = CryptoJS.AES.decrypt(
        localStorage.getItem("userPassword"),
        salt
      )
        ?.toString(CryptoJS.enc.Utf8)
        .replace('"', "")
        .replace('"', "");

      dispatch(loginAction(email, pass, navigate));
    };
  } else if (
    localStorage.getItem("remember") === "true" &&
    localStorage.getItem("loggedIn") === "false" &&
    localStorage.getItem("AutoLogin") === "false"
  ) {
    setTimeout(() => {
      navigate("/sign-in");
      window.location.reload();
    }, 100);
  } else {
    setTimeout(() => {
      navigate("/page-register");
      window.location.reload();
    }, 100);
  }
}

export function loginAction(email, password, navigate, goTo = null, thread_id) {
  return (dispatch) => {
    login(email, password, localStorage.getItem("plansom_bot_id"))
      .then((response) => {
        localStorage.setItem("isPartner", response?.data?.data?.is_partner)
        localStorage.removeItem("invite_key");
        if (response.data.message === "User with this email does not exists ") {
          const errorMessage = formatError(response.data.message);
          dispatch(loginFailedAction(errorMessage));
        } else if (response.data.message === "Incorrect password") {
          const errorMessage = formatError(response.data.message);
          dispatch(loginFailedAction(errorMessage));
        } else {
          localStorage.removeItem("strategyStatus");
          localStorage.removeItem("organization_id");
          saveTokenInLocalStorage(response.data);
          dispatch(loginConfirmedAction(response.data));
          localStorage.setItem("loggedIn", true);
          localStorage.setItem("timescope", "1 month");
          if (goTo === "stripe") {
            navigate("/stripe");
          } else if (
            response.data?.org_access === false ||
            response.data?.data?.org_access === false ||
            response.data?.org_access === "false" ||
            response.data?.data?.org_access === "false"
          ) {
            navigate("/organization-list");
            localStorage.setItem("org_access", response.data.data.org_access);

            if (response?.data?.data?.user_role === "owner") {
              // localStorage.setItem("scope", "organization");
              localStorage.setItem("scope", "personal");
            } else {
              // localStorage.setItem("scope", "organization");
              localStorage.setItem("scope", "personal");
            }
          } else {
            navigate("/my-day");
            localStorage.setItem(
              "organization_id",
              response.data.data.default_organization
            );
            localStorage.setItem("org_access", response.data.data.org_access);
            if (response?.data?.data?.user_role === "owner") {
              localStorage.setItem("scope", "personal");
            } else {
              localStorage.setItem("scope", "personal");
            }
          }
          localStorage.setItem("ActiveChat", null);
          localStorage.setItem("AutoLogin", true);
        }
      })
      .catch((error) => {
        swal("Oops", error?.response?.data?.message);
        dispatch(
          loginFailedAction("User with this email address does not exist")
        );
      });
  };
}
export function getUserdetail(user_id, token) {
  return async (dispatch) => {
    await getUserdetails(user_id, token)
      .then((response) => {
        dispatch(userDetailsConfirmedAction(response.data));
      })
      .catch((error) => {
        dispatch(userDetailsFailedAction("Failed to get user details"));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}
export function userDetailsFailedAction(data) {
  return {
    type: USER_DETAILS_FAILED_ACTION,
    payload: data,
  };
}
export function forgotFailedAction(data) {
  return {
    type: FORGOT_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  localStorage.removeItem("invite_key");
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}
export function userDetailsConfirmedAction(data) {
  return {
    type: USER_DETAILS_CONFIRM_ACTION,
    payload: data,
  };
}
export function confirmedChangePassAction(data) {
  return {
    type: CHANGE_PASSWORD_CONFIRMED_ACTION,
    payload: data,
  };
}
export function changePassFailedAction(data) {
  return {
    type: CHANGE_PASSWORD_FAILED_ACTION,
    payload: data,
  };
}

export function forgotPassword(email) {
  return (dispatch) => {
    forgotpass(email)
      .then((response) => {
        dispatch(confirmedForgotAction(response.data));
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(forgotFailedAction(errorMessage));
      });
  };
}

export function changePassword(email, password) {
  return (dispatch) => {
    return resetPassword(email, password) // Return the promise
      .then((response) => {
        dispatch(confirmedChangePassAction(response.data));
        return response; // Return the response for further use
      })
      .catch((error) => {
        swal("Oops", error?.response?.data?.message);
        throw error; // Re-throw the error to propagate it
      });
  };
}


export function verify_OTP(otp, email) {
  return (dispatch) => {
    return verifyOTP(otp, email)
      .then((response) => {
        if (response?.data?.data?.user_role === "owner") {
          localStorage.setItem("scope", "personal");
        } else {
          localStorage.setItem("scope", "personal");
        }
        dispatch(confirmedOtpVerificationAction(response.data));
      })
      .catch((error) => {
        swal("Oops", error?.response?.data?.message);
        throw error; // Re-throw the error to allow handling in the caller
      });
  };
}

export function verify_SignupOTP(otp, email, navigate, thread_id) {
  return (dispatch) => {
    verifySignupOTP(otp, email, thread_id)
      .then((response) => {
        localStorage.setItem("loggedIn", true)
        localStorage.setItem(
          "organization_id",
          response?.data?.data?.default_organization
        );
        if (response.data?.data !== undefined) {
          saveTokenInLocalStorage(response.data);
          dispatch(confirmedSignupOtpAction(response.data));
          localStorage.setItem("timescope", "1 month");
          if (response.data.data.org_access === false) {
            window.location.replace("/user-register/success");
          } else if (response?.data?.data?.org_access === true && response?.data?.user_role === "user") {
            window.location.replace("/invite-user-register/success");
          } else {
            navigate("/my-day");
          }
          if (response?.data?.data?.user_role === 'owner') {

            //  localStorage.setItem("scope", "organization");
            localStorage.setItem("scope", "personal")
          } else {
            // localStorage.setItem("scope", "organization");
            localStorage.setItem("scope", "personal")
          }
          localStorage.setItem("org_access", response.data.data.org_access);
        } else {

          saveTokenInLocalStorage(response.data);
          dispatch(confirmedSignupOtpAction(response));
          localStorage.setItem("timescope", "1 month");
          localStorage.setItem("org_access", response.data.org_access);
          navigate("/organization-list");
        }
      })
      .catch((error) => {
        dispatch(signupOtpFailedAction(error?.response?.data?.message));
        swal("Oops", error?.response?.data?.message);
      });
  };
}

export function inviteUserAction(email, role, organization_id, token) {
  return (dispatch) => {
    inviteUser(email, role, organization_id, token)
      .then((response) => {
        dispatch(confirmedInviteUserpAction(response.data));
      })
      .catch((error) => {
        dispatch(InviteUserFailedAction("Failed to invite User"));
      });
  };
}

export function confirmedSignupAction(payload) {
  localStorage.removeItem("payload-->", payload);
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}
export function confirmedForgotAction(payload) {
  return {
    type: FORGOT_CONFIRMED_ACTION,
    payload,
  };
}
export function confirmedresendOTPAction(payload) {
  return {
    type: RESEND_OTP_CONFIRMED_ACTION,
    payload,
  };
}
export function confirmedOtpVerificationAction(message) {
  return {
    type: VERIFY_OTP_CONFIRMED_ACTION,
    payload: message,
  };
}
export function otpVerificationFailedAction(message) {
  return {
    type: VERIFY_OTP_FAILED_ACTION,
    payload: message,
  };
}
export function confirmedSignupOtpAction(message) {
  return {
    type: VERIFY_OTP_SIGNUP_CONFIRMED_ACTION,
    payload: message,
  };
}
export function signupOtpFailedAction(message) {
  return {
    type: VERIFY_OTP_SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}
export function resendOTPFailedAction(message) {
  return {
    type: RESEND_OTP_FAILED_ACTION,
    payload: message,
  };
}
export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
export function confirmedInviteUserpAction(message) {
  return {
    type: INVITE_USER_CONFIRMED_ACTION,
    payload: message,
  };
}
export function InviteUserFailedAction(message) {
  return {
    type: INVITE_USER_FAILED_ACTION,
    payload: message,
  };
}

export function confirmedInvitePartnerAction(message) {
  return {
    type: INVITE_PARTNER_CONFIRMED_ACTION,
    payload: message,
  };
}

export function failedInvitePartnerAction(message) {
  return {
    type: INVITE_PARTNER_FAILED_ACTION,
    payload: message,
  };
}

export function partnerLoginAction(email, password, navigate) {
  return (dispatch) => {
    PartenerSignIn(email, password)
      .then((response) => {
        localStorage.setItem("loggedIn", true)
        localStorage.setItem("isPartner", response?.data?.data?.is_partner)
        if (response.data?.data !== undefined) {
          saveTokenInLocalStorage(response.data);
          dispatch(confirmedInvitePartnerAction(response.data));
          localStorage.setItem("timescope", "1 month");
          if (response.data.data.org_access === false) {
            // window.location.replace("/user-register/success");
            // navigate("/organization-list");
          } else if (response?.data?.data?.org_access === true && response?.data?.user_role === "user") {
            // window.location.replace("/invite-user-register/success");
            // navigate("/organization-list");
          } else {
            navigate("/my-day");
          }
          if (response?.data?.data?.user_role === 'owner') {
            localStorage.setItem("scope", "personal")
          } else {
            localStorage.setItem("scope", "personal")
          }
          localStorage.setItem("org_access", response.data.data.org_access);
        } else {

          saveTokenInLocalStorage(response.data);
          dispatch(confirmedSignupOtpAction(response));
          localStorage.setItem("timescope", "1 month");
          localStorage.setItem("org_access", response.data.org_access);
          navigate("/organization-list");
        }
        navigate("/organization-list");
      })
      .catch((error) => {
        dispatch(failedInvitePartnerAction(error?.response?.data?.message));
        swal("Oops", error?.response?.data?.message);
      });
  };
}