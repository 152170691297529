import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Button, Skeleton, Divider } from "@mui/material";
import { Progress } from "antd";
import { getAllGoalsByScope, getGoalPreviewList } from "../../../services/GoalService";
import { fetchAllMyDayData, fetchAllTasksDataOnMyDay, fetchGoalsProgressData } from "../../../store/actions/NotificationAction";
import { useDispatch } from "react-redux";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import Star2 from '../../../images/Plansom/Star2.png'

const PlanProgress = () => {
  const navigate = useNavigate();
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);
  const dispatch = useDispatch();
  const orgId = localStorage.getItem("organization_id");
  const [goalsList, setGoalsList] = useState();
  const [percent, setPercent] = useState(60);
  const [timeLeft, setTimeLeft] = useState((100 - parseFloat(localStorage.getItem("progressPercent"))) || 40);
  const [retryCount, setRetryCount] = useState(1);
  const [mainGoalName, setMainGoalName] = useState("");
  const [message, setMessage] = useState(`Welcome, ${userInfoDetails?.data?.first_name}. We are analyzing your inputs...`);
  const [visibleCount, setVisibleCount] = useState(0);
  const [showGoToMyPlanScreen, setShowGoToMyPlanScreen] = useState(false);
  const [progressBarCompleted, setProgressBarCompleted] = useState(false);

  useEffect(() => {
    if (!goalsList?.subgoals?.length) return;

    const timeInSeconds = Math.round((100 - parseFloat(localStorage.getItem("progressPercent"))) / goalsList?.subgoals?.length);
    const intervalTime = timeInSeconds * 1000;

    const interval = setInterval(() => {
      setVisibleCount((prevCount) => {
        if (prevCount >= goalsList?.subgoals?.length) {
          clearInterval(interval);
          return prevCount;
        }
        return prevCount + 1;
      });
    }, intervalTime);

    return () => clearInterval(interval);
  }, [goalsList?.subgoals?.length]);


  useEffect(() => {
    const stopPercent = 100;
    const intervalTime = 1000;
    const progressDuration = 40;
    const percentIncrement = (100 - 60) / progressDuration;

    const checkGoals = async () => {
      try {
        const res = await getAllGoalsByScope(
          orgId,
          userInfoDetails?.data?.accessToken,
          "all"
        );
        const optionData = res?.data?.data;
        if (optionData.length >= 1) {
          dispatch(fetchAllMyDayData(true));
          dispatch(fetchGoalsProgressData(true));
          dispatch(fetchAllTasksDataOnMyDay(true));
          setPercent(100);
        } else {
          handleRetry();
        }
      } catch (error) {
        console.error("API call failed:", error);
        handleRetry();
      }
    };

    const handleRetry = () => {
      const maxRetries = 10;
      const retryIntervalTime = 60 * 2000;

      if (retryCount < maxRetries) {
        setRetryCount((prev) => prev + 1);
        setTimeout(checkGoals, retryIntervalTime * retryCount);
      }
    };

    const storedPercent = parseFloat(localStorage.getItem("progressPercent")) || 60;
    const lastUpdated = parseInt(localStorage.getItem("lastProgressUpdated"), 10) || Date.now();
    const elapsedSeconds = Math.floor((Date.now() - lastUpdated) / 1000);
    const initialPercent = Math.min(storedPercent + elapsedSeconds * (percentIncrement / (intervalTime / 1000)), stopPercent);

    setPercent(initialPercent);

    const timerInterval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerInterval);
          checkGoals();
          return 0;
        }
        return prevTime - 1;
      });

      setPercent((prevPercent) => {
        const newPercent = Math.min(prevPercent + percentIncrement, stopPercent);
        localStorage.setItem("progressPercent", newPercent.toFixed(2));
        localStorage.setItem("lastProgressUpdated", Date.now().toString());

        if (newPercent >= 99) {
          setProgressBarCompleted(true);
        }
        if (newPercent >= stopPercent) {
          setShowGoToMyPlanScreen(true);
          clearInterval(timerInterval);
          checkGoals();
        }

        return newPercent;
      });
    }, intervalTime);

    return () => clearInterval(timerInterval);
  }, [retryCount]);

  const formatTimeLeft = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    const storedPercent = parseFloat(localStorage.getItem("progressPercent"));
    return storedPercent === 100 ? `00:00 sec left` : `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")} sec left`;
  };

  useEffect(() => {
    if (timeLeft === 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  useEffect(() => {
    if (goalsList) {
      setMessage(`Welcome, ${userInfoDetails?.data?.first_name}. Your plan “${mainGoalName}” is almost ready!`);
    }
    if (parseFloat(localStorage.getItem("progressPercent")) === 100) {
      setMessage(`Welcome, ${userInfoDetails?.data?.first_name}. Your plan “${mainGoalName}” is ready now!`);
    }
  }, [goalsList]);

  useEffect(() => {
    if (showGoToMyPlanScreen) {
      setMessage(`Welcome, ${userInfoDetails?.data?.first_name}. Your plan “${mainGoalName}” is ready now!`);
    }
    if (progressBarCompleted) {
      setShowGoToMyPlanScreen(true)
    }
  }, [showGoToMyPlanScreen, progressBarCompleted]);

  async function getGoalsPreviews(retryCount = 0) {
    const maxRetries = 5;
    const retryInterval = 2000;

    try {
      const res = await getGoalPreviewList(userInfoDetails?.data?.accessToken);
      if (res?.data?.data?.length > 0) {
        setGoalsList(res?.data?.data[0]);
        setMainGoalName(res?.data?.data[0]?.name);
      } else if (retryCount < maxRetries) {
        setTimeout(() => getGoalsPreviews(retryCount + 1), retryInterval);
      } else {
        console.warn("Max retries reached. Condition not satisfied.");
      }
    } catch (err) {
      console.error("Error fetching goals preview details:", err);
      if (retryCount < maxRetries) {
        setTimeout(() => getGoalsPreviews(retryCount + 1), retryInterval);
      }
    }
  }

  const handleButtonClick = () => {
    localStorage.setItem("OnboardingSkeleton", false);
    localStorage.removeItem("progressPercent");
    localStorage.removeItem("lastProgressUpdated");
    navigate("/goals");
  };

  useEffect(() => {
    const storedPercent = localStorage.getItem("progressPercent");
    if (storedPercent && parseFloat(storedPercent) === 100) {
      getGoalsPreviews();
    } else {
      setTimeout(() => {
        getGoalsPreviews();
      }, 1000);
    }
  }, [])

  return (
    <>
      <Box
        sx={{
          width: "100%",
          borderRadius: 4,
          background: "linear-gradient(to right, #F8F9FF, #FDFDFE)",
          p: 4,
          boxShadow: 2,
          overflow: "hidden",
          position: 'relative'
        }}
      >
        <img
          src={Star2}
          alt="star"
          style={{
            position: "absolute",
            top: "10%",
            left: "80%",
            width: "60px",
            // opacity: 0.8, 
          }}
        />

        <img
          src={Star2}
          alt="star"
          style={{
            position: "absolute",
            top: "60%",
            left: "88%",
            width: "50px",

          }}
        />

        <img
          src={Star2}
          alt="star"
          style={{
            position: "absolute",
            top: "27%",
            left: "92%",
            width: "90px",

          }}
        />

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4} md={3} lg={2} xl={2} style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}>
            <Box position="relative" display="inline-flex">
              <Progress
                type="dashboard"
                percent={Math.round(percent)}
                strokeColor={"#1D3BAF"}
                strokeWidth={10}
                width={120}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} md={9} lg={10} xl={10}>
            <Grid container spacing={2} rowSpacing={3}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#E8EBF7",
                    color: "#1D3BAF",
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontWeight: "bold",
                    borderRadius: "20px",
                    textTransform: "none",
                    padding: "5px 20px",
                    "&:hover": {
                      backgroundColor: "#E8EBF7",
                    },
                  }}
                >
                  {formatTimeLeft(timeLeft)}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: 600,
                    fontFamily: "Bricolage Grotesque",
                    color: "#05060E"
                  }}
                >
                  {message}
                </Typography>
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: 400,
                    fontFamily: "Inter",
                    color: "#565C76"
                  }}
                >
                  {goalsList ? (
                    <>
                      {
                        parseFloat(localStorage.getItem("progressPercent")) === 100 ?
                          "You can check you goals list now" :
                          <>
                            Take a look at the subgoals that are part of your plan.
                            <br />
                            The final touches are being added!
                          </>
                      }
                    </>
                  ) : (
                    "This will take a few seconds to start building your plan."
                  )}

                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          width: "100%",
          borderRadius: 4,
          background: "linear-gradient(to right, #F8F9FF, #FDFDFE)",
          padding: "20px 0px 20px 0px",
          boxShadow: 2,
          overflow: "hidden",
          position: "relative",
          mt: 4,
        }}
      >
        {
          showGoToMyPlanScreen &&
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backdropFilter: "blur(5px)",
              zIndex: 2,
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                gap: 1,
              }}
            >
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: 600,
                  fontFamily: "Bricolage Grotesque",
                  color: "#05060E",
                }}
              >
                Your plan is all set!
              </Typography>
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  fontFamily: "Inter",
                  color: "#565C76",
                }}
              >
                Click below to explore it in detail on the Goals page
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#1D3BAF",
                  color: "#E8EBF7",
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontWeight: "bold",
                  borderRadius: "20px",
                  textTransform: "none",
                  padding: "5px 20px",
                  marginTop: "10px",
                  "&:hover": {
                    backgroundColor: "#1D3BAF",
                  },
                }}
                onClick={handleButtonClick}
              >
                Go to My Plan
              </Button>
            </Box>
          </div>
        }

        <Grid container direction="column" p={0}>
          <Grid
            item
            sx={{
              width: "100%",
              borderBottom: "1px solid #DDDEE4",
              pb: 1,
            }}
          >
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: 600,
                fontFamily: "Bricolage Grotesque",
                color: "#05060E",
                paddingLeft: "20px",
              }}
            >
              Goals list
            </Typography>
          </Grid>
          {goalsList ?
            <Grid
              container
              direction="column"
              spacing={2}
              p={"5px 25px 10px 30px"}
              mt={0}
            >
              {goalsList?.subgoals?.map((item, index) => (
                <Grid item key={index}>
                  {index < visibleCount ? (
                    <Grid
                      item
                      sx={{
                        width: "100%",
                        borderBottom: item?.subgoals?.length !== 0 ? "1px solid #DDDEE4" : "",
                        pb: 1,
                        mt: 0,
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          fontFamily: "Bricolage Grotesque",
                          color: "#05060E",
                        }}
                      >
                        {item?.name}
                      </Typography>
                      <Divider
                        sx={{
                          backgroundColor: "#DDDEE4",
                          width: "100%",
                          marginTop: 1,
                          marginBottom: 1,
                        }}
                      />
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          width: "100%",
                          padding: "5px 20px 0px 20px",
                        }}
                      >
                        {item?.subgoals?.map((subgoal, index) => (
                          <React.Fragment key={index}>
                            <Grid
                              item
                              sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                p: "0px 5px 10px 5px",
                              }}
                            >
                              <Typography
                                component="div"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  fontFamily: "Inter",
                                  color: "#565C76",
                                }}
                              >
                                <GoDotFill style={{ marginRight: "5px" }} />
                                {subgoal?.name}
                              </Typography>

                            </Grid>
                            {index !== item?.subgoals?.length - 1 && (
                              <Divider
                                orientation="horizontal"
                                flexItem
                                sx={{
                                  backgroundColor: "#DDDEE4",
                                  width: "90%",
                                  marginLeft: 1,
                                }}
                              />
                            )}
                          </React.Fragment>
                        ))}
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      sx={{
                        width: "100%",
                        borderBottom: "1px solid #DDDEE4",
                        pb: 1,
                        mt: 0,
                      }}
                    >
                      <Skeleton variant="text" width="80%" height={25} />
                      <Divider
                        sx={{
                          backgroundColor: "#DDDEE4",
                          width: "100%",
                          marginTop: 1,
                          marginBottom: 1,
                        }}
                      />
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          width: "100%",
                          padding: "5px 20px 0px 20px",
                        }}
                      >
                        {Array.from({ length: 5 }).map((_, index) => (
                          <React.Fragment key={index}>
                            <Grid
                              item
                              sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                p: "0px 5px 10px 5px",
                              }}
                            >
                              <Skeleton variant="text" width="60%" height={15} />
                            </Grid>
                            {index !== 5 - 1 && (
                              <Divider
                                orientation="horizontal"
                                flexItem
                                sx={{
                                  backgroundColor: "#DDDEE4",
                                  width: "90%",
                                  marginLeft: 1,
                                }}
                              />
                            )}
                          </React.Fragment>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid> :
            <Grid
              container
              direction="column"
              spacing={2}
              p={"5px 25px 10px 30px"}
              mt={0}
            >
              {Array.from({ length: 5 }).map(() => (
                <Grid item>
                  <Grid
                    item
                    sx={{
                      width: "100%",
                      borderBottom: "1px solid #DDDEE4",
                      pb: 1,
                      mt: 0,
                    }}
                  >
                    <Skeleton variant="text" width="80%" height={25} />
                    <Divider
                      sx={{
                        backgroundColor: "#DDDEE4",
                        width: "100%",
                        marginTop: 1,
                        marginBottom: 1,
                      }}
                    />
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        width: "100%",
                        padding: "5px 20px 0px 20px",
                      }}
                    >
                      {Array.from({ length: 5 }).map((_, index) => (
                        <React.Fragment key={index}>
                          <Grid
                            item
                            sx={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              p: "0px 5px 10px 5px",
                            }}
                          >
                            <Skeleton variant="text" width="60%" height={15} />
                          </Grid>
                          {index !== 5 - 1 && (
                            <Divider
                              orientation="horizontal"
                              flexItem
                              sx={{
                                backgroundColor: "#DDDEE4",
                                width: "90%",
                                marginLeft: 1,
                              }}
                            />
                          )}
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          }
        </Grid>
      </Box>
    </>
  );
};

export default PlanProgress;