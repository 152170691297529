
import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";

const termsData = {
  sections: [
    {
      title: "1. Introduction",
      content: `Welcome to Plansom.com ("Plansom"). These Terms and Conditions ("Terms") govern your use of our Software-as-a-Service ("Services") platform and provide important information about your rights and obligations.`,
    },
    {
      title: "2. Acceptance of Terms",
      content: `By accessing and using the Services, you acknowledge and agree that data collection and processing are integral to the functionality and delivery of the Services. If you do not agree with these Terms, please discontinue your use of the Services.`,
    },
    {
      title: "3.1 Subscription plans",
      content: `Plansom offers various subscription plans. Details of these plans, including pricing, features, and billing cycles, can be found on our website.`,
    },
    {
      title: "3.2 Payment",
      content: `By subscribing to our Services, you agree to pay the applicable fees as specified in your chosen subscription plan. Payment terms and methods will be outlined on our website.`,
    },
    {
      title: "3.3 Billing and renewal",
      content: `Your subscription will automatically renew at the end of each billing cycle unless you cancel it. You are responsible for keeping your billing and contact information up to date.`,
    },
    {
      title: "3.4 Monthly subscriptions",
      content: `Subscribers on a monthly plan will be billed on a recurring monthly basis. The subscription may be canceled at any time. Upon cancellation, you will retain access to the Services until the end of your current billing cycle. Organizations that have multiple users will be billed monthly for all users on the same day. All user accounts will be billed, regardless of if a particular user has actively accessed an account during the billing cycle.`,
    },
    {
      title: "3.5 Annual subscriptions",
      content: `Subscribers who choose an annual subscription will be billed for the entire year upfront. If you cancel your annual subscription, you will continue to have access to the Services until the end of your prepaid year, after which the subscription will not renew. Organizations that have multiple users will be billed annually for all users on the same day. All user accounts will be billed, regardless of if a particular user has actively accessed an account during the billing cycle.`,
    },
    {
      title: "4. No refunds",
      content: `Payments made are non-refundable. Whether you use the Services or not, once a payment is made, you are not entitled to a refund. This applies to both monthly and annual suscription plans.`,
    },
    {
      title: "5. Service availability",
      content: `While we aim to ensure the Services are always available, there will be times when the Services may be interrupted, e.g., for maintenance or upgrades. We will make every effort to communicate any anticipated downtime. Services interruption will not be compensated for.`,
    },
    {
      title: "6. Use of the Services",
      content: ``,
    },
    {
      title: "6.1 Eligibility",
      content: `You must be at least 18 years old to use the Services. By using the Services, you represent and warrant that you meet this eligibility requirement.`,
    },
    {
      title: "6.2 User account",
      content: `To access and use the Services, you are required to create a user account. You are responsible for maintaining the confidentiality of your account information and are fully responsible for all activities that occur under your account. You are responsible for backing up your own data and Plansom is not responsible for any data loss. Notify Plansom immediately if you detect any unauthorized use of your account.`,
    },
    {
      title: "6.3 Acceptable use",
      content: `You agree not to use the Services for any unlawful or prohibited purposes. This includes, but is not limited to, activities that violate applicable laws, infringe upon the rights of others, or harm the security or integrity of the Services.`,
    },
    {
      title: "7. Termination",
      content: `We reserve the right to terminate or suspend your account at any time, without notice, for any reason, including but not limited to a breach of these Terms and Conditions. If we do so without any breach on your part, we will provide a pro-rata refund for any unused portion of the month or year, as applicable.`,
    },
    {
      title: "8. Data privacy",
      content: `Your use of the Services involves the collection and processing of data as described in the Privacy Policy. This data is necessary to provide and improve the Services and forms an integral part of their functionality.`,
    },
    {
      title: "9. Intellectual Property",
      content: ``,
    },
    {
      title: "9.1 Ownership",
      content: `All intellectual property rights, including trademarks, copyrights, patents, and proprietary
  algorithms, related to the Services, are the property of Plansom. Any data generated as a
  result of your use remains your intellectual property.`,
    },
    {
      title: "9.2 Use of data",
      content: `Plansom processes aggregated and anonymized data generated through the use of our
  Services for operational improvements, including enhancing our AI models and
  benchmarking purposes. This data does not contain personally identifiable information and is
  essential to delivering the Services.`,
    },
    {
      title: "9.3 License",
      content: `Subject to these Terms and Conditions, Plansom grants you a limited, non-exclusive, non-
  transferable license to use the Services for your internal business purposes. This license does not extend to the algorithms, methods, or models used by Plansom in the provision of
  the Services.`,
    },
    {
      title: "10. Limitation of Liability",
      content: `To the fullest extent permitted by applicable law, Plansom, its affiliates, directors,
  employees, or its licensors shall not be liable for (a) any indirect, incidental, exemplary
  punitive, or consequential damages of any kind whatsoever; (b) loss of profits, revenue,
  data, use, goodwill, or other intangible losses; (c) damages relating to your access to, use of,
  or inability to access or use the Services; (d) damages relating to any conduct or content of
  any third party or user using the Services, including without limitation, defamatory, offensive
  or illegal conduct or content; and/or (e) damages in any manner relating to any content. This
  limitation applies to all claims, whether based on warranty, contract, tort, or any other legal
  theory, whether or not Plansom has been informed of the possibility of such damage, and
  further where a remedy set forth herein is found to have failed its essential purpose.`,
    },
    {
      title: "11. Disclaimer",
      content: `The Service is provided &quot;as-is&quot; and &quot;as available&quot; without any warranties of any kind, either
  express or implied.`,
    },
    {
      title: "12. Indemnification",
      content: `You agree to defend, indemnify, and hold harmless Plansom, its officers, directors,
  employees, and agents from any claims or demands arising from your use of the Service or
  violation of these Terms.`,
    },
    {
      title: "13. Data Processor and Data Controller",
      subsections: [
        {
          subtitle: "Plansom operates as:",
          content: [
            {
              heading: "1. Data Processor:",
              points: [
                "○ Plansom processes personal data solely on behalf of and under the instructions of the customer.",
                "○ As a Data Processor, Plansom will:",
                [
                  "■ Process personal data only as necessary to deliver the Services.",
                  "■ Implement appropriate technical and organizational measures to protect personal data.",
                  "■ Assist the customer, where reasonable, in fulfilling its obligations under data protection laws (e.g., facilitating responses to data subject requests).",
                ],
              ],
            },
            {
              heading: "2. Data Controller (Aggregated Data):",
              points: [
                "○ Plansom acts as a Data Controller for aggregated and anonymized data used to improve our Services.",
                "○ This data is stripped of personally identifiable information and processed in compliance with global data protection laws.",
              ],
            },
          ],
        },
        {
          subtitle: "Customer Responsibility as Data Controller:",
          content: [
            {
              heading:
                "● The customer is responsible for the collection, management, and processing of personal data related to its users. This includes:",
              points: [
                [
                  "○ Obtaining necessary consents from data subjects.",
                  "○ Managing data subject requests (e.g., access, correction, or deletion of personal data).",
                  "○ Ensuring compliance with all applicable data protection laws in relation to the data processed through the Services.",
                ],
              ],
            },
          ],
        },
      ],
    },
    {
      title: "14. Changes to the Terms",
      content: `Plansom may update these Terms from time to time. Any changes will be effective
  immediately upon posting the updated Terms on our website. Your continued use of the
  Services after the posting of changes constitutes your acceptance of the modified terms.`,
    },
    {
      title: "15. Governing law and dispute resolution",
      content: `These Terms shall be governed by and construed in accordance with the laws of Hong
  Kong. Any dispute, controversy, difference or claim arising out of or relating to this contract,
  including the existence, validity, interpretation, performance, breach or termination thereof or
  any dispute regarding non-contractual obligations arising out of or relating to it shall be
  referred to and finally resolved by arbitration administered by the Hong Kong International
  Arbitration Centre under the Hong Kong International Arbitration Centre Administered
  Arbitration rules in force when the Notice of Arbitration is submitted. The seat of arbitration
  shall be Hong Kong. The number of arbitrators shall be one. The arbitration proceedings
  shall be conducted in English.`,
    },
    {
      title: "16. Waiver and severability",
      content: `If any provision is deemed invalid, the rest remain in effect. Any failure to enforce any
  provision of these terms is not a waiver of such provision..`,
    },
    {
      title: "17. Entire agreement",
      content: `These Terms, along with the Privacy Policy and the Cookie Policy, constitute the entire
  agreement between you and Plansom.`,
    },
    {
      title: "18. Contact information",
      content: `Plansom.com, is operated by CDSA Limited, a limited company organized under the laws of
  Hong Kong. Throughout the site, the terms “Plansom”, &quot;we&quot;, &quot;our&quot;, and &quot;us&quot; refer to CDSA
  Limited, trading as Plansom.com.
  If you have any questions or concerns regarding these Terms and Conditions, please
  contact us at support [at] plansom [dot] com.`,
    },
  ],
};

const TermsAndCondition = () => {
  const renderPoints = (points) => {
    return (
      <List sx={{ paddingLeft: 4 }}>
        {points.map((point, index) => (
          <React.Fragment key={index}>
            {Array.isArray(point) ? (
              renderPoints(point)
            ) : (
              <ListItem sx={{ paddingY: 0 }}>
                <ListItemText primary={point} />
              </ListItem>
            )}
          </React.Fragment>
        ))}
      </List>
    );
  };

  return (
    <Box
      sx={{
        background: "#F8F9FF",
        padding: { xs: "4rem 2rem", sm: "3rem 2rem", md: "5rem 7rem" },
      }}
    >
      <Typography variant="h4" gutterBottom>
        Terms and Conditions
      </Typography>
      <Typography variant="body1" sx={{ mb: 1 }}>Last updated: January 7th, 2025</Typography>


      {termsData.sections.map((section, index) => (
        <Box key={index} sx={{ marginTop: 3 }}>
          <Typography variant="h5" gutterBottom>
            {section.title}
          </Typography>
          {section.content && (
            <Typography variant="body1" paragraph>
              {section.content}
            </Typography>
          )}
          {section.subsections &&
            section.subsections.map((subsection, subIndex) => (
              <Box key={subIndex} sx={{ marginTop: 2 }}>
                <Typography variant="h6" gutterBottom>
                  {subsection.subtitle}
                </Typography>
                {subsection.content.map((content, contentIndex) => (
                  <Box key={contentIndex} sx={{ marginTop: 1 }}>
                    {content.heading && (
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        style={{ marginLeft: "2rem" }}
                      >
                        {content.heading}
                      </Typography>
                    )}
                    {content.points && renderPoints(content.points)}
                  </Box>
                ))}
              </Box>
            ))}
        </Box>
      ))}
    </Box>
  );
};

export default TermsAndCondition;
