import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import PlannedObjectives from "./PlannedObjectives";
import PlannedTasks from "./PlannedTasks";
import { getMyDay } from "../../../services/OrganizationService";
import {
  Grid,
} from "@mui/material";
import { getQuickWinsData } from "../../../services/OrganizationService";
import { useDispatch, useSelector } from "react-redux";
import AiCoach from "./AiCoach";
import TaskCard from "./TaskCard";
import { getAllGoalsByOrgID } from "../../../services/GoalService";
import MyDayProgressBar from "./MyDayProgressBar";
import { fetchAllMyDayData, fetchAllTasksDataOnMyDay, fetchGoalsProgressData } from "../../../store/actions/NotificationAction";

export default function Plan({ route, teamId }) {
  const dispatch = useDispatch();
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);
  const [dayDetails, setDayDetails] = useState(null);
  const [goals, setGoals] = useState([]);
  const [displayQuickWinCard, setDisplayQuickWinCard] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  const getAllTheGoal = () => {
    const data = getAllGoalsByOrgID(
      userInfoDetails?.data?.accessToken,
      null,
      "",
      "",
      "",
      "",
      true,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      3,
      "",
      "",
      ""
    );
    data?.then(async (res) => {
      setGoals(res?.data?.data);
    });
    data?.catch(async (err) => {
      console.log("error getAllGoalsByOrgID", err);
    });
  };

  useEffect(() => {
    const isKeyTrue = localStorage.getItem("OnboardingSkeleton") === "true";

    if (isKeyTrue) {
      setShowProgressBar(true);
      setRefreshData(true);
    }
    else {
      setShowProgressBar(false);
      if (refreshData) {
        setTimeout(() => {
          dispatch(fetchAllMyDayData(true));
          dispatch(fetchGoalsProgressData(true));
          dispatch(fetchAllTasksDataOnMyDay(true));
          getQuickWinsData();
          setRefreshData(false);
        }, 10000)
      }
    }
  }, [localStorage.getItem("OnboardingSkeleton")]);

  useEffect(() => {
    if (localStorage.getItem("OnboardingSkeleton") === "true") {
      setShowProgressBar(true);
    }
    else {
      setShowProgressBar(false);
    }
    getAllTheGoal();
  }, []);

  useEffect(() => {

    if (localStorage.getItem("OnboardingSkeleton") == false) {
      window.location.reload()
    }
  }, [localStorage.getItem("OnboardingSkeleton")])

  useEffect(() => {
    getMyPanDetails(
      localStorage.getItem("scope"),
      localStorage.getItem("team_id")
    );
  }, []);

  const getMyPanDetails = async (scope, teamId) => {
    try {
      const data = await getMyDay(userInfoDetails?.data?.accessToken, teamId);
      setDayDetails(data?.data?.data);
    } catch (err) {
      console.error("Error fetching data", err);
    }
  };

  const getQuickWinsDatas = () => {
    const data = getQuickWinsData(userInfoDetails?.data?.accessToken);
    data.then(async (res) => {
      if (res?.data?.data?.quick_wins_data?.length === 0 && res?.data?.data?.streak_count === 0) {
        if (showProgressBar) {
          setDisplayQuickWinCard(true);
        } else {
          setDisplayQuickWinCard(false);
        }
      }
      else {
        setDisplayQuickWinCard(true);
      }
    });
    data.catch(async (err) => {
      // setSubShowTableSpinner(false);
    });
  };

  useEffect(() => {
    getQuickWinsDatas();
  }, []);

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {showProgressBar ? (
          <Grid item xs={12} md={12} lg={12}>
            <MyDayProgressBar />
          </Grid>
        ) :
          (<>
            {displayQuickWinCard && (
              <Grid item xs={12} md={6} lg={4}>
                <TaskCard />
              </Grid>
            )}
            {
              goals?.length > 0 &&
              < Grid item xs={12} md={6} lg={4}>
                <PlannedObjectives objectiveList={dayDetails?.objective_list} />
              </Grid>
            }
            <Grid item xs={12} md={12} lg={4}>
              <AiCoach />
            </Grid>
          </>)
        }
      </Grid>
      {
        !showProgressBar &&
        <Grid container spacing={2} >
          <Grid item xs={12} md={12}>
            <Card>
              <Card.Body>
                <PlannedTasks
                  taskList={dayDetails?.task_list}
                  taskTitle={dayDetails?.task_title}
                />
              </Card.Body>
            </Card>
          </Grid>
        </Grid>
      }
    </>
  );
}
