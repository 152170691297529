import React from "react";
import {
  Card,
  CardContent,
  Typography,
  LinearProgress,
  Box,
  Skeleton,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { useState, useEffect } from "react";
import { getAllGoalsByOrgID } from "../../../services/GoalService";
import completedGoal from "../../../images/goalCompleted.png";
import Vector_disable from '../../../images/Plansom/Vector_disable.png'
import RocketFilled from '../../../images/Plansom/RocketFilled.png'
import InfoCircleFilled from '../../../images/Plansom/InfoCircleFilled.png'
import { useNavigate } from "react-router-dom";
import { updatedGoals, updatedGoalsWins } from "../../../store/actions/TaskActions";
import { useDispatch, useSelector } from "react-redux";
import { updatedGoalsEdit } from "../../../store/actions/TaskActions";
import { fetchGoalsProgressData } from "../../../store/actions/NotificationAction";

const PlannedObjectives = () => {
  const [goals, setGoals] = useState([]);
  const [fetchingGoals, setFetchingGoals] = useState(false);
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const stateData = useSelector((state) => state);

  const showGoals = (event, goal) => {
    event.preventDefault();
    navigate("/goals", { state: { goal: goal } });
    console.log("selected goal", goal);
  };

  const getAllTheGoal = () => {
    setFetchingGoals(true);
    const data = getAllGoalsByOrgID(
      userInfoDetails?.data?.accessToken,
      null,
      "",
      "",
      "",
      "",
      true,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      3,
      "",
      "",
      ""
    );
    data?.then(async (res) => {
      dispatch(fetchGoalsProgressData(false));
      setGoals(res?.data?.data);
      if (localStorage.getItem("OnboardingSkeleton") === "true") {
        setFetchingGoals(true);
      }
      else {
        setFetchingGoals(false);
      }
      dispatch(updatedGoals(false))
      dispatch(updatedGoalsEdit(false))
      dispatch(updatedGoalsWins(false))

    });
    data?.catch(async (err) => {
      console.log("error getAllGoalsByOrgID", err);
    });
  };

  useEffect(() => {
    if (stateData.messages.fetchGoalsProgressData) {
      console.log("in useeffect", stateData.messages.fetchGoalsProgressData);
      getAllTheGoal();
    }
  }, [stateData]);

  useEffect(() => {
    getAllTheGoal();
  }, []);

  useEffect(() => {
    if (stateData.task.updatedGoals) {
      getAllTheGoal()
    }
  }, [stateData.task.updatedGoals])
  useEffect(() => {
    if (stateData.task.updatedGoalsEdit) {
      getAllTheGoal()
    }
  }, [stateData.task.updatedGoalsEdit])

  useEffect(() => {
    if (stateData.task.updatedGoalsWins) {
      getAllTheGoal()
    }
  }, [stateData.task.updatedGoalsWins])

  const getGoalIcon = (goal) => {
    if (goal?.status === 'Inactive') {
      return <img src={Vector_disable} alt="complete" />;
    }
    else if (goal?.goal_status === "On track")
      return <img src={completedGoal} alt="about to complete" />;
    else if (goal?.goal_status === "At risk")
      return <WarningAmberIcon sx={{ color: "#FFA726" }} />;
    else if (goal?.goal_status === "Failed") {
      return <RemoveCircleOutlineOutlinedIcon sx={{ color: "#FF4D4F" }} />;
    } else if (goal?.goal_status === "Failing") {
      return <img src={RocketFilled} alt="Failing" />;
    }
  };

  return (
    <>
      {goals?.length == 0 ? "" :
        <Card
          sx={{
            borderRadius: 4,
            padding: 2,
            minHeight: "46vh",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
          }}
        >
          <CardContent>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mb: fetchingGoals ? 5 : 3 }}>
              Goals Progress
            </Typography>
            {
              fetchingGoals ? <>
                <Skeleton style={{ marginBottom: "7%" }} />
                <Skeleton style={{ marginBottom: "7%" }} />
                <Skeleton style={{ marginBottom: "7%" }} />
                <Skeleton style={{ marginBottom: "7%" }} />
                <Skeleton style={{ marginBottom: "7%" }} />
              </> :
                <>
                  {goals?.map((goal, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                      <Box sx={{ display: "flex", alignItems: "center", mb: 1, cursor: "pointer" }} onClick={(event) => showGoals(event, goal)}>
                        {getGoalIcon(goal)}


                        <Typography sx={{ ml: 1, flexGrow: 1 }}>{goal?.name}</Typography>
                        <Typography sx={{ fontWeight: "bold" }}>

                          {goal?.goal_status === "Failing" ? (
                            <span>
                              Failing: {goal?.goal_completed_percent}%{" "}
                              <img src={RocketFilled} alt="Failing" />
                            </span>
                          ) : (
                            `${goal?.goal_completed_percent}%`
                          )}
                        </Typography>
                      </Box>
                      <LinearProgress
                        sx={{
                          height: 8,
                          borderRadius: 5,
                          backgroundColor: "#DDDEE4",
                          "& .MuiLinearProgress-bar": {
                            bgcolor:
                              goal?.status === "Inactive"
                                ? "#DDDEE4"
                                : goal?.goal_status === "On track"
                                  ? "#0B9060"
                                  : goal?.goal_status === "At risk"
                                    ? "#FAAD14"
                                    : "#FF4D4F",
                          },
                        }}
                        variant="determinate"
                        value={goal?.goal_completed_percent}
                      />
                    </Box>
                  ))}
                </>
            }
          </CardContent>
        </Card>}
    </>
  );

};

export default PlannedObjectives;
