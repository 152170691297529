import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getUsersForShare, sharingTask } from "../../../services/TeamService";
import { toast } from "react-toastify";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@mui/material";

const ShareTaskModal = (props) => {
  const [shareModel, setShareModel] = useState(props?.shareModel);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [usersOption, setUsersOption] = useState([]);
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);
  const [error, setError] = useState();
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const handleShareTask = (event) => {
    const orgId = localStorage.getItem("organization_id");
    event.preventDefault();
    const sharedData = defaultOptions?.map((option) => ({
      id: option.userId,
      type: option.isTeam ? "team" : "user",
    }));

    const payload = {
      organization_id: orgId,
      task_id: props?.task?.id,
      shared_data: sharedData,
    };

    sharingTask(userInfoDetails?.data?.accessToken, payload)
      .then(async (res) => {
        props?.setShareModelParent(false);
        setDefaultOptions([]);
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                className="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2 style={{ fontSize: "22px" }}>Completed</h2>
                <h3 style={{ fontSize: "18px" }}>
                  Your task has been shared successfully!
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "task-schedule",
            className: "toast-success-container toast-success-container-after ",
          }
        );
      })
      .catch(async (err) => {
        setError(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    setShareModel(props?.shareModel);
  }, [props]);

  const changeUserOption = (e, userRole) => {
    setDefaultOptions(userRole);
  };

  const handleInputChange = async (e, newValue) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setDebounceTimeout(
      setTimeout(async () => {
        if (newValue !== "") {
          try {
            const query = { search_text: newValue };
            const res = await getUsersForShare(userInfoDetails?.data?.accessToken, query);
            const optionGoalLists = res?.data?.data?.map((option) => {
              if (option?.team_members) {
                return {
                  label: option.name,
                  value: option.name,
                  userId: option.id,
                  isTeam: true,
                };
              } else {
                return {
                  label: option.first_name + " " + option.last_name,
                  value: option.first_name + " " + option.last_name,
                  userId: option.id,
                  isTeam: false,
                };
              }
            });
            setUsersOption(optionGoalLists);
          } catch (err) {
            console.log(err);
          }
        } else {
          setUsersOption([]);
        }
      }, 500)
    );
  };

  return (
    <>
      <Modal className="fade bd-example-modal-lg" show={shareModel} size="lg">
        <Modal.Body>
          <form onSubmit={handleShareTask}>
            <div className="d-flex" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div className="col">
                <h2 className="text-center">Share - {props?.task?.name}</h2>
              </div>
              <button
                type="button"
                style={{ marginLeft: "20px" }}
                className="btn-close float-end text-center"
                onClick={() => (
                  props?.setShareModelParent(false),
                  setDefaultOptions([]),
                  setError("")
                )}
              ></button>
            </div>
            <div className="modal-body p-0 mt-2">
              <div className="form-group mb-3">
                <h5>Search users</h5>
                <Autocomplete
                  multiple
                  id="user-select-autocomplete"
                  options={usersOption}
                  getOptionLabel={(option) => option.label}
                  filterSelectedOptions
                  onChange={changeUserOption}
                  onInputChange={handleInputChange}
                  popupIcon={null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search users or teams"
                      variant="outlined"
                    />
                  )}
                />
              </div>
              {error && <div className="text-danger fs-12 ms-2">{error}</div>}
            </div>
            <input
              type="submit"
              value="Share"
              className="float-end figma-btn text-white"
            />
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShareTaskModal;
