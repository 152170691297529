import axios from 'axios';
import config from './config';
const baseURL = config.BASE_URL;

export function getAllCountByOrg(orgId, token) {
    return axios.get(
        baseURL + "organizations/" + orgId + "/badge-counter", {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

export function getAllMessageByOrg(orgId, start, end, token) {
    return axios.get(
        baseURL + `messages/org/` + orgId, {
        headers: {
            Authorization: 'Bearer ' + token
        },
        params: {
            start_index: start,
            end_index: end
        }
    }
    );
}

export function acceptTaskAction(message, token) {
    const postData = {
        message_id: message.id
    };
    return axios.post(
        baseURL + `tasks/notify/` + message.task.id + '/accept', postData, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

export function acceptGoalAction(message, token) {
    const postData = {
        message_id: message.id
    };
    return axios.post(
        baseURL + `goals/notify/` + message.goal.id + '/accept', postData, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}


export function approveTaskAction(message, token) {
    const postData = {
        message_id: message.id
    };
    return axios.post(
        baseURL + `tasks/notify-owner/` + message.task.id + '/approve', postData, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

// export function approveGoalAction(message, token) {
//     const postData = {
//         message_id: message.id
//     };
//     return axios.post(
//         baseURL + `goals/notify/` + message.goal.id + '/accept', postData, {
//         headers: {
//             Authorization: 'Bearer ' + token
//         }
//     }
//     );
// }


export function rejectedTaskAction(task, reasonDetails, msgId, token) {
    const postData = {
        reject_reason: reasonDetails?.rejected_reason,
        reason_description: reasonDetails?.rejected_description,
        message_id: msgId.id
    };
    return axios.post(
        baseURL + `tasks/notify-owner/` + task?.id + '/reject', postData, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

export function rejectTaskAction(task, reasonDetails, msgId, token) {
    const postData = {
        reject_reason: reasonDetails?.reject_reason,
        reason_description: reasonDetails?.reject_description,
        message_id: msgId.id
    };
    return axios.post(
        baseURL + `tasks/notify/` + task?.id + '/reject', postData, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

export function rejectGoalAction(task, reasonDetails, msgId, token) {
    const postData = {
        reject_reason: reasonDetails?.reject_reason,
        reason_description: reasonDetails?.reject_description,
        message_id: msgId.id
    };
    return axios.post(
        baseURL + `goals/notify/` + task?.id + '/reject', postData, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

export function sendMessageAction(msg, messageType, receiver, orgId, selectedTeam, userInfo) {
    const postData = {
        sender: userInfo?.data?.id,
        receiver: receiver?.receiver?.id,
        message_body: msg,
        organization: orgId?.organization,
        message_type: messageType?.message_type,
        team: selectedTeam?.team
    };
    return axios.post(
        baseURL + `messages/ping`, postData, {
        headers: {
            Authorization: 'Bearer ' + userInfo?.accessToken
        }
    }
    );
}

export function sendMessageCommentAction(desc, taskDetailsinfo, file, userInfo) {
    let goal_id = ''
    let task_id = ''
    if (taskDetailsinfo?.goal_creator) {
        goal_id = taskDetailsinfo.id
    }
    else {
        task_id = taskDetailsinfo.id
    }
    const postData = {
        user: userInfo?.user?.id || userInfo?.data?.id,
        task: task_id, //taskDetailsinfo?.task?.id || 
        description: desc,
        name: desc,
        goal: goal_id,
        attachments: file
    };
    return axios.post(
        baseURL + `comments`, postData, {
        headers: {
            'Content-Type': "multipart/form-data",
            Authorization: 'Bearer ' + userInfo?.data?.accessToken
        }
    }
    );
}

export function sendMessageCommentsActions(desc, taskDetailsinfo, file, userInfo) {
    let goal_id = ''
    let task_id = ''
    if (taskDetailsinfo?.goal) {
        goal_id = taskDetailsinfo?.goal?.id
    }
    else {
        task_id = taskDetailsinfo?.task?.id
    }
    const postData = {
        user: userInfo?.user?.id || userInfo?.data?.id,
        task: task_id, //taskDetailsinfo?.task?.id || 
        description: desc,
        name: desc,
        goal: goal_id,
        attachments: file
    };
    return axios.post(
        baseURL + `comments`, postData, {
        headers: {
            'Content-Type': "multipart/form-data",
            Authorization: 'Bearer ' + userInfo?.data?.accessToken
        }
    }
    );
}

export function sendMessageCommentActions(desc, taskDetailsinfo, file, userInfo) {
    let goal_id = ''
    let task_id = ''
    if (taskDetailsinfo.goal_creator) {
        goal_id = taskDetailsinfo.id
    }
    else {
        task_id = taskDetailsinfo.id
    }
    const postData = {
        user: userInfo?.user?.id || userInfo?.data?.id,
        task: taskDetailsinfo?.task?.id || task_id,
        description: desc,
        name: desc,
        goal: taskDetailsinfo?.goal?.id || goal_id,
        attachments: file
    };
    return axios.post(
        baseURL + `comments`, postData, {
        headers: {
            'Content-Type': "multipart/form-data",
            Authorization: 'Bearer ' + userInfo?.data?.accessToken
        }
    }
    );
}

export function readMessages(id, userInfo) {
    const postData = {
        message_ids: id
    }
    return axios.post(
        baseURL + `messages`, postData, {
        headers: {
            Authorization: 'Bearer ' + userInfo?.accessToken
        }
    }
    );
}

export function getAllMessageCommentsById(taskId, goalId, token) {
    if (taskId != undefined) {
        return axios.get(
            baseURL + `comments/task/` + taskId + `/retrieve`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
        );
    }
    else if (goalId != undefined) {
        return axios.get(
            baseURL + `comments/goal/` + goalId + `/retrieve`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
        );
    }
}

export function getAllChatMessageById(room_id, start, stop, token) {
    const postData = {
        start_index: start,
        end_index: stop
    }
    return axios.post(
        baseURL + "chat/conversations/" + room_id,
        postData, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

export function getAllThreadRoomById(id, start, stop, token) {
    const postData = {
        organization_id: id,
        start_index: start,
        end_index: stop
    }
    return axios.post(
        baseURL + "chat/thread/list",
        postData,
        {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
    );
}

export function getAllThreadsIdList(id, token) {
    const postData = {
        organization_id: id
    }
    return axios.post(
        baseURL + "chat/thread_id/list",
        postData,
        {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
    );
}

export function getThreadDetails(id, token) {
    return axios.post(
        baseURL + `chat/${id}/get_thread_details`,
        {},
        {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
    );
}


export function createPersonalRoom(orgId, userId, roomType, thread_topic, task, goal, userInfo, files) {
    let users = []
    if (task || goal) {
        if (task)
            users = task?.task_creator?.id === task?.task_owner?.id ? [task?.task_creator?.id] : [task?.task_owner?.id]

        if (goal)
            users = goal?.goal_creator?.id === goal?.goal_owner?.id ? [goal?.goal_creator?.id] : [goal?.goal_owner?.id]
    }
    else {
        users = [userId]
    }
    const formData = new FormData();
    formData.append("organization", orgId);
    formData.append("receiver", JSON.stringify(users));
    formData.append("thread_type", roomType);
    formData.append("thread_topic", thread_topic);
    formData.append("initiator", task?.task_creator?.id || goal?.goal_creator?.id || userInfo?.id);
    formData.append("task", task?.id);
    formData.append("goal", goal?.id);
    formData.append("thread_image", files);

    return axios.post(
        baseURL + "chat/start-thread",
        formData,
        {
            headers: {
                Authorization: 'Bearer ' + userInfo?.accessToken,
                'Content-Type': "multipart/form-data",
            }
        }
    );
}

export function createGroupRoom(orgId, userId, room_name, roomType, task, goal, userInfo, files) {
    const formData = new FormData();
    formData.append("organization", orgId);
    formData.append("receiver", JSON.stringify(userId));
    formData.append("thread_type", roomType);
    formData.append("thread_topic", room_name);
    formData.append("initiator", userInfo?.id);
    formData.append("task", task);
    formData.append("goal", goal);
    formData.append("thread_image", files);

    return axios.post(
        baseURL + "chat/start-thread",
        formData,
        {
            headers: {
                Authorization: 'Bearer ' + userInfo?.accessToken,
                'Content-Type': "multipart/form-data",
            }
        }
    );
}

export function deleteChatFromRoom(id, delete_both, token) {
    const postData = {
        conversation_ids: [id],
        delete_both: delete_both
    }
    return axios.post(
        baseURL + `chat/conversations/delete`,
        postData,
        {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
    );
}

export function AddnewMemberTOThread(roomId, memberId, token) {
    const postData = {
        receiver: memberId,
        thread_type: memberId.length === 1 ? "personal" : "group",
    }
    return axios.post(
        baseURL + `chat/` + roomId + `/update`,
        postData,
        {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
    );
}
export function updateThread(roomId, thread_topic, token) {
    const postData = {
        thread_topic: thread_topic,
    }
    return axios.post(
        baseURL + `chat/` + roomId + `/update`,
        postData,
        {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
    );
}

export function DeleteMemberFromThread(roomId, memberId, token) {
    const postData = {
        organization_id: localStorage.getItem('organization_id'),
        receiver: memberId,
        // thread_type: memberId.length === 1 ? "personal" : "group",
    }
    return axios.post(
        baseURL + `chat/` + roomId + `/remove-members`,
        postData,
        {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
    );
}

export function UpdateMessage(msg_id, roomId, txt_msg, token) {
    const postData = {
        organization_id: localStorage.getItem('organization_id'),
        thread_id: roomId,
        text: txt_msg
    }
    return axios.post(
        baseURL + `chat/conversation/` + msg_id + `/update`,
        postData,
        {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
    );
}

export function deleteRoomWithId(id, token) {
    const postData = {
        organization_id: localStorage.getItem('organization_id')
    }
    return axios.post(
        baseURL + `chat/` + id + `/delete`,
        postData,
        {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
    );
}

export function uploadImageToThread(roomId, thread_image, token) {
    const postData = {
        thread_image: thread_image,
    }
    return axios.post(
        baseURL + `chat/` + roomId + `/update`,
        postData,
        {
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': "multipart/form-data",
            }
        }
    );
}

export function sendAttachmentMessageAction(sender, text, room_id, attachment, token) {
    const postData = {
        sender: sender,
        text: text,
        thread: JSON.parse(localStorage.getItem('ActiveChat'))?.id,
        attachments: attachment,
    };
    if (postData.thread) {
        return axios.post(
            baseURL + `chat/attachments/add`, postData, {
            headers: {
                'Content-Type': "multipart/form-data",
                Authorization: 'Bearer ' + token
            }
        }
        );
    }
}


export function readAllMessageByRoomId(roomId, token) {
    return axios.get(
        baseURL + `chat/` + roomId + `/mark-as-read`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}


export function deleteAttachment(attachment_id, token) {
    const postData = {
        attachment_ids: attachment_id,
        thread_id: JSON.parse(localStorage.getItem('ActiveChat'))?.id,
    };
    if (postData.thread) {
        return axios.post(
            baseURL + `chat/attachments/delete`, postData, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
        );
    }
}

export function createThread(task, goal, thread_topic, chat_room, token) {
    const postData = {
        task: task,
        goal: goal,
        thread_topic: thread_topic,
        chat_room: chat_room
    };
    // if(postData.room_id){
    return axios.post(
        baseURL + `chat/start-thread`, postData, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
    // }
}

export function getAllthreadByRoomId(roomId, token) {
    return axios.get(
        baseURL + `chat/threads/` + roomId, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

export function deleteThread(room_id, token) {
    return axios.delete(
        baseURL + `chat/` + room_id + `/delete-thread`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

export function getAllChatMessageByThreadID(thread_id, start, stop, token) {
    const postData = {
        start_index: start,
        end_index: stop
    }
    return axios.post(
        baseURL + "chat/conversations/" + thread_id,
        postData, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

export function getSearchThreads(org_id, searchTerm, start, end, token) {
    const postData = {
        organization_id: org_id,
        search: searchTerm,
        start_index: start,
        end_index: end
    }
    return axios.post(
        baseURL + "chat/thread/search",
        postData, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

export function getCSVFile(promptArray, token) {
    console.log("PARAMETER", token)
    const postData = {
        "prompt": promptArray
    }
    return axios.post(
        baseURL + "plansom-bot",
        postData, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

export function unsubscribe_Mail(id) {
    return axios.post(
        baseURL + `users/unsubscribe/${id}`
    );
}

// export function getAllBotQuestions(token) {
//     const userDetails = localStorage.getItem("userDetails");
//     const userInfoDetails = JSON.parse(userDetails);
//     console.log(localStorage.getItem("userDetails")=== 'true', "MMMMMMMMMMMUUUUUUU")
//     return axios.get(
//         baseURL + `chat/chat-bot-questions/list`,
//         {

//             headers: {
//                 // Authorization: 'Bearer ' + token
//             }
//         }
//     );
// }

export function getAllBotQuestions(token) {
    const userDetails = localStorage.getItem("userDetails");
    const userInfoDetails = JSON.parse(userDetails);
    console.log(userInfoDetails?.data?.accessToken, userInfoDetails?.data?.accessToken !== undefined, userInfoDetails?.data?.accessToken !== "", "MMMMMMMMMMMUUUUUUU");

    let requestConfig = {
        method: 'get',
        url: baseURL + 'chat/chat-bot-questions/list',
    };
    if (localStorage.getItem("loggedIn") == "true" && userInfoDetails?.data?.accessToken !== undefined) {
        console.log("hiiiiiiiiiiiii")
        requestConfig.headers = {
            Authorization: 'Bearer ' + userInfoDetails?.data?.accessToken
        };
    }
    return axios(requestConfig);
}



export function confirmationToAddMemberInThread(roomId, memberId, token) {
    const postData = {
        receiver: memberId,
        // thread_type: memberId.length === 1 ? "personal" : "group",
    }
    return axios.post(
        baseURL + `chat/` + roomId + `/thread-member`,
        postData,
        {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
    );
}


export function listOfemailNotifications(token) {
    return axios.get(
        baseURL + `users/email-notification-setting/list`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}



export function emailNotificationUpdates(id, token) {
    const postData = {

    };
    return axios.put(
        baseURL + `users/email-notification-setting/update/` + id,
        postData,
        {
            headers: {
                Authorization: "Bearer " + token,
            },
        }
    );
}


export function listOfGetCoachDetails(token) {
    return axios.get(
        baseURL + `users/coach-setting/list`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}

export function coachDtailsUpdateUpdates(id, is_default, name, token) {
    const postData = {
        id: id,
        is_default: is_default,
        name: name

    };
    return axios.put(
        baseURL + `users/update/coach_setting/` + id,
        postData,
        {
            headers: {
                Authorization: "Bearer " + token,
            },
        }
    );
}

export function deleteCoach(id, token) {
    return axios.delete(
        baseURL + `users/delete/coach/` + id, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}


export function createCoach(name, description, token) {
    const postData = {
        name: name,
        description: description
    }
    return axios.post(
        baseURL + "users/coach/create",
        postData,
        {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
    );
}


export function addingComment(message, token, comment) {
    console.log(message, comment, "addingComment")
    const postData = {
        message_id: message,
        comments: comment
    };
    return axios.post(
        baseURL + 'comments/message', postData, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    }
    );
}