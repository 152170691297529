import React from "react";
import { Typography, Box } from "@mui/material";

const policyData = {
  
  sections: [
    {
      title: "Data retention ",
      content:
        "Your data is retained only as long as necessary to fulfill the purposes for which it was collected or as required by law."
    },
    {
      title: "International data transfers",
      content:
        "Should we transfer your data outside the EEA, we'll ensure protective measures are in place, compliant with GDPR and relevant Canadian standards."
    },
    {
      title: "Security measures",
      content:
        "We maintain industry-standard measures, such as encryption and access controls, to protect your data, ensuring the safeguarding level is proportional to the sensitivity of the information."
    },
    {
      title: "Cookies and tracking technologies",
      content:
        "We use cookies and tracking technologies for authentication and analytics. Please refer to our Cookie Policy for comprehensive details on types, purposes, and durations."
    },
    {
      title: "Data breach notification",
      content:
        "In the event of a breach that might jeopardize your rights and freedoms, we commit to informing you within 72 hours of awareness."
    },
    {
      title: "Changes to this Privacy Policy",
      content:
        "We may modify this Privacy Policy in response to new data practices. Significant changes will be communicated via email or the application. Periodic reviews are encouraged."
    },
    {
      title: "Accountability and openness",
      content:
        "At Plansom.com, we have designated a Privacy Officer responsible for our compliance with PIPEDA and this policy. For any questions, concerns, or to exercise any of your rights under GDPR, PIPEDA, or CCPA, please contact our Privacy Officer at support [at] plansom [dot] com."
    },
    {
      title: "Consent",
      content:
        "Your use of the platform constitutes acknowledgment and agreement to the data practices described. These practices are integral to delivering and improving our Services."
    }
  ]
};

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        background: "#F8F9FF",
        padding: { xs: "4rem 2rem", sm: "3rem 2rem", md: "5rem 7rem" }
      }}
    >
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="body1" sx={{ mb: 1 }}>Last updated: January 7th, 2025</Typography>
      <Typography variant="body1" paragraph>
        Welcome to Plansom.com. We prioritize your privacy and are committed to
        safeguarding your personal data. This Privacy Policy outlines how we
        gather, use, and protect your information when you interact with our
        application.
      </Typography>

      {/* Information We Collect */}
      <Typography variant="h5" gutterBottom>
        Information We Collect and the Legal Basis for Processing
      </Typography>
      <Typography variant="body1" gutterBottom>
        We gather and process the following data types:
      </Typography>
      <Box sx={{ pl: 2, mb: 0 }}>
        <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
          ● User account information: When you create an account with Plansom,
          we gather:
        </Typography>
        <Box sx={{ pl: 4, mb: 1 }}>
          {[
            "○ Name",
            "○ Email address",
            "○ First name and last name",
            "○ Phone number",
            "○ Address, State, Province, ZIP/Postal code, City, Country"
          ].map((item, index) => (
            <Typography key={index} variant="body1" sx={{ mb: 0.5 }}>
              {item}
            </Typography>
          ))}
        </Box>
        <Typography variant="body1" fontWeight="bold" sx={{ mb: 2 }}>
          ● User-generated content (e.g., goals, tasks, usage statistics, goal
          and task outcomes, etc.)
        </Typography>
      </Box>

      <Typography variant="body1" sx={{ mb: 2}} gutterBottom>
        Legal basis: This processing is necessary for the performance of our
        agreement with you.
      </Typography>

      {/* Data Sharing */}
      <Typography variant="h5" gutterBottom>
        Data Sharing
      </Typography>
      <Box sx={{ pl: 2, mb: 0 }}>
        <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
          ● With third parties:
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          We only share your data with specified third-party service providers
          who assist us with services like analytics, customer support, and
          payment processing. These third parties are required to respect
          privacy laws and handle your data with the utmost care.
        </Typography>

        <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
          ● Legal requirements:
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          If mandated by law or a legitimate legal process, we may disclose your
          data.
        </Typography>
      </Box>

      {/* User Choices and Rights */}
      <Typography variant="h5" gutterBottom>
        User Choices and Rights
      </Typography>
      <Box sx={{ pl: 2, mb: 0 }}>
        <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
          ● Access, update, and accuracy:
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          You can access and modify your personal data via your account
          settings. We strive to ensure that personal information is as
          accurate, complete, and up-to-date as necessary for its intended use.
        </Typography>

        <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
          ● Opt-out:
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Users may opt out of specific non-essential data processing, such as
          marketing communications. However, data processing necessary for
          service functionality and improvement is non-optional.
        </Typography>

        <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
          ● Data portability:
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          You have the right to receive your data in a structured, commonly
          used, machine-readable format.
        </Typography>

        <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
          ● Restriction and objection:
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          You can request restrictions or object to our processing of your data
          in certain situations.
        </Typography>
      </Box>

      {/* California Consumer Privacy Act (CCPA) */}
      <Typography variant="h5" gutterBottom>
        For California Residents
      </Typography>
      <Typography variant="body1" gutterBottom>
        The California Consumer Privacy Act (CCPA) provides certain rights
        related to your personal information:
      </Typography>
      <Box sx={{ pl: 2, mb: 0 }}>
        <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
          ● Access, update, and accuracy:
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
          You can access and modify your personal data via your account
          settings. We endeavor to ensure that personal information remains
          accurate, complete, and up-to-date as required for its intended
          purposes.
        </Typography>

        <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
          ● Opt-out:
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
          Users may opt out of specific non-essential data processing, such as
          marketing communications. However, data processing necessary for
          service functionality and improvement is non-optional.
        </Typography>

        <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
          ● Data portability:
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
          You have the right to request a copy of your data in a structured,
          commonly used format.
        </Typography>

        <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
          ● Deletion:
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
          California residents have the right to request deletion of their
          personal data, subject to certain exceptions.
        </Typography>

        <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
          ● Non-discrimination:
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          We will not discriminate against you for exercising any of your CCPA
          rights.
        </Typography>
      </Box>

      {/* Automated Decision-Making */}
      <Typography variant="h5" gutterBottom>
        Automated Decision-Making
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
        Our Services rely on aggregated and anonymized data for AI-driven
        recommendations and clustering. These processes are necessary for
        optimal service functionality and user experience. You have the right
        to:
      </Typography>
      <Box sx={{ pl: 2, mb: 0 }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          ○ Be informed about the logic, significance, and consequences of such
          processing.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          ○ Obtain human intervention for any automated decision.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          ○ Express your point of view before any decision that might impact
          you.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          ○ Contest decisions made purely based on automated processing.
        </Typography>
      </Box>

      {policyData.sections.map((section, index) => (
        <Box key={index} sx={{ marginTop: 3 }}>
          <Typography variant="h5" gutterBottom>
            {section.title}
          </Typography>
          <Typography variant="body1" paragraph>
            {section.content}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default PrivacyPolicy;
