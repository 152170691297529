import {
    createTaskService,
    deleteTask,
    editTask,
    userSpecificTask,
    userSpecificTaskHistory
} from '../../services/TaskService';

import {
    formatError,
} from '../../services/AuthService';

export const CREATE_TASK_CONFIRMED_ACTION = '[createTaskService action] confirmed createTaskService';
export const CREATE_TASK_FAILED_ACTION = '[createTaskService action] failed createTaskService';
export const DELETE_TASK_CONFIRMED_ACTION = '[deleteTask action] confirmed deleteTask';
export const DELETE_TASK_FAILED_ACTION = '[deleteTask action] failed deleteTask';
export const EDIT_TASK_CONFIRMED_ACTION = '[editTask action] confirmed editTask';
export const EDIT_TASK_FAILED_ACTION = '[editTask action] failed editTask';
export const USER_SPECIFIC_TASK_CONFIRMED_ACTION = '[userSpecificTask action] confirmed userSpecificTask';
export const USER_SPECIFIC_TASK_FAILED_ACTION = '[userSpecificTask action] failed userSpecificTask';

export const TASK_LATE_COUNT_UPDATE = 'TASK_LATE_COUNT_UPDATE'

export const GETTING_ALL_THREAD_FROM_WEBSOCKET = 'GETTING_ALL_THREAD_FROM_WEBSOCKET'
export const TASK_SHOWING_FILTER = 'TASK_SHOWING_FILTER'

export const MAKE_DEFAULT_TEMPLATE = 'MAKE_DEFAULT_TEMPLATE'
export const GOAL_NAVIGATION_MANAGE = 'GOAL_NAVIGATION_MANAGE'
export const REFRESH_TASK_LIST = "REFRESH_TASK_LIST"
export const GOAL_EDIT_MANAGE = 'GOAL_EDIT_MANAGE'
export const PLANNED_GOAL_MY_DAY = 'PLANNED_GOAL_MY_DAY'
export const UPDATE_STREAK = 'UPDATE_STREAK'
export const UPDATE_PROGRESS_BAR = 'UPDATE_PROGRESS_BAR'
export const UPDATED_GOALS = 'UPDATED_GOALS'
export const UPDATED_STREAK_EDIT = 'UPDATED_STREAK_EDIT'
export const UPDATED_PROGRESS_BAR_EDIT = 'UPDATED_PROGRESS_BAR_EDIT'
export const UPDATED_GOALS_EDIT = 'UPDATED_GOALS_EDIT'
export const UPDATED_STREAK_WINS = 'UPDATED_STREAK_WINS'
export const UPDATED_PROGRESS_BAR_WINS = 'UPDATED_PROGRESS_BAR_WINS'
export const UPDATED_GOALS_WINS = 'UPDATED_GOALS_WINS'

export function createTaskAction(orgId, user_id, taskOwner, taskForm, taskImpact, goalDetails, token) {
    return (dispatch) => {
        createTaskService(orgId, user_id, taskOwner, taskForm, taskImpact, goalDetails, token)
            .then((response) => {
                dispatch(createTaskConfirmAction(response.data));
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(createTaskFailedAction(errorMessage));
            });
    };

}

export function createTaskConfirmAction(data) {
    return {
        type: CREATE_TASK_CONFIRMED_ACTION,
        payload: data,
    };
}
export function createTaskFailedAction(message) {
    return {
        type: CREATE_TASK_FAILED_ACTION,
        payload: message,
    }
}

export function deleteTaskAction(task_id, token) {
    return (dispatch) => {
        deleteTask(task_id, token)
            .then((response) => {
                dispatch(deleteTaskConfirmAction(response.data));
            })
            .catch((error) => {
                console.log('error', error)
                const errorMessage = formatError(error.response.data);
                dispatch(deleteTaskFailedAction(errorMessage));
            });
    };

}

export function deleteTaskConfirmAction(data) {
    return {
        type: DELETE_TASK_CONFIRMED_ACTION,
        payload: data,
    };
}
export function deleteTaskFailedAction(data) {
    return {
        type: DELETE_TASK_FAILED_ACTION,
        payload: data,
    }
}


export function editTaskAction(orgId, task_id, taskOwner, taskImpact, taskDetail, token) {
    return (dispatch) => {
        editTask(orgId, task_id, taskOwner, taskImpact, taskDetail, token)
            .then((response) => {
                dispatch(editTaskConfirmAction(response.data));
            })
            .catch((error) => {
                console.log('error', error)
                const errorMessage = formatError(error.response.data);
                dispatch(editTaskFailedAction(errorMessage));
            });
    };

}

export function editTaskConfirmAction(data) {
    return {
        type: EDIT_TASK_CONFIRMED_ACTION,
        payload: data,
    };
}
export function editTaskFailedAction(data) {
    return {
        type: EDIT_TASK_FAILED_ACTION,
        payload: data,
    }
}

export function getUserSpecificTaskAction(org_id, token, pageUpcomingCount = 0) {
    return (dispatch) => {
        userSpecificTask(org_id, token, pageUpcomingCount ? pageUpcomingCount : 1)
            .then((response) => {
                dispatch(userSpecificTaskConfirmAction(response.data));
            })
            .catch((error) => {
                console.log('error', error)
                const errorMessage = formatError(error.response.data);
                dispatch(userSpecificTaskFailedAction(errorMessage));
            });
    };

}

export function getUserSpecificTaskHistory(org_id, token) {
    // return (dispatch) => {
    const data = userSpecificTaskHistory(org_id, token)
    data?.then((response) => {
        return response.data.data
        // dispatch(response.data.data);
    })
    data?.catch((error) => {
        console.log('error', error)
        const errorMessage = formatError(error.response.data);
        // dispatch(errorMessage);
        return errorMessage
    });
    // };

}

export function userSpecificTaskConfirmAction(data) {
    return {
        type: USER_SPECIFIC_TASK_CONFIRMED_ACTION,
        payload: data,
    };
}
export function userSpecificTaskFailedAction(data) {
    return {
        type: USER_SPECIFIC_TASK_FAILED_ACTION,
        payload: data,
    }
}


export function taskLateCountUpdate(data) {
    console.log(data, "taskLateCountUpdate")
    return {
        type: TASK_LATE_COUNT_UPDATE,
        payload: data
    }
}

export function gettingAllThreadByWebsocket(data) {
    return {
        type: GETTING_ALL_THREAD_FROM_WEBSOCKET,
        payload: data
    }
}

export function taskShowingFilter(data) {
    console.log(data, "taskLateCountUpdate")
    return {
        type: TASK_SHOWING_FILTER,
        payload: data
    }
}

export function makeDefaultTemplate(data) {
    return {
        type: MAKE_DEFAULT_TEMPLATE,
        payload: data
    }
}

export function goalNavigationManage(data) {
    return {
        type: GOAL_NAVIGATION_MANAGE,
        payload: data
    }
}

export function refreshTaskList(data) {
    return {
        type: REFRESH_TASK_LIST,
        payload: data
    }
}

export function goalEditManage(data) {
    return {
        type: GOAL_EDIT_MANAGE,
        payload: data
    }
}

export function plannedGoalMyDay(data) {
    return {
        type: PLANNED_GOAL_MY_DAY,
        payload: data
    }
}

export function updateStreak(data){
    return{
        type: UPDATE_STREAK,
        payload: data
    }
}

export function updateProgressBar(data){
    return{
        type: UPDATE_PROGRESS_BAR,
        payload: data
    }
}

export function updatedGoals(data){
    return{
        type: UPDATED_GOALS,
        payload: data
    }
}

export function updatedStreakEdit(data){
    return{
        type: UPDATED_STREAK_EDIT,
        payload: data
    }
}
export function updatedProgressBarEdit(data){
    return{
        type: UPDATED_PROGRESS_BAR_EDIT,
        payload: data
    }
}
export function updatedGoalsEdit(data){
    return{
        type: UPDATED_GOALS_EDIT,
        payload: data
    }
}

export function updatedStreakWins(data){
    return{
        type: UPDATED_STREAK_WINS,
        payload: data
    }
}

export function updatedProgressBarWins(data){
    return{
        type: UPDATED_PROGRESS_BAR_WINS,
        payload: data
    }
}

export function updatedGoalsWins(data){
    return{
        type: UPDATED_GOALS_WINS,
        payload: data
    }
}








